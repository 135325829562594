<template>
    <div>
        <span v-for='(route, index) in $route.matched' :key='index'>
            <span v-if='index !== 0' class='text-grey-250 mr-1'> / </span>
            <span v-if='index === $route.matched.length - 1' class='font-black text-grey-500'>
                {{ computedName(route.name) }}
            </span>
            <router-link v-else
                         :to='computeLink(route)'
                         class='text-grey-400 hover:no-underline mr-1 focus:no-underline focus:text-grey-400'
            >
                {{ computedName(route.name) }}
            </router-link>
        </span>
    </div>
</template>

<script>
export default {
    name: 'breadcrumb',
    props: {
        more: {
            type: Object,
            default: () => { },
        },
    },
    methods: {
        computeLink (route) {
            let name;
            switch (route.name) {
                case 'environment':
                    name = 'dashboard';
                    break;
                case 'client-detail-page':
                    name = 'fidu-client-uid-info';
                    break;
                case 'org-administration':
                    name = 'organization-administration-users';
                    break;
                default:
                    name = route.name;
                    break;
            }
            return {
                name,
            };
        },
        computedName (name) {
            if (this.more.hasOwnProperty(name)) {
                return `${this.more[name]}`;
            } else {
                return this.$t(`nav-${name}`);
            }
        },
    },
};
</script>
