import Vue from 'vue';
import VueRouter from 'vue-router';
import Signup from './user/SignUp.vue';
import ChangePassword from './user/ChangePassword.vue';
import ConfirmInvitation from './user/ConfirmInvitation.vue';
import MaintenancePage from './common/MaintenancePage.vue';

import EmailVerificationWizard from './client/EmailVerificationWizard.vue';
import RestartEmailVerification from './client/RestartEmailVerification.vue';

import Fiduciary from './fiduciary/Fiduciary.vue';
import Dashboard from './fiduciary/Dashboard.vue';
import CodaSearch from './fiduciary/CodaSearch.vue';
import SodaSearch from './fiduciary/SodaSearch.vue';
import ClientSearch from './fiduciary/ClientSearch.vue';
import ClientTransfer from './fiduciary/ClientTransfer.vue';
import ClientRegularTransferList from './fiduciary/ClientRegularTransferList.vue';
import ClientPlatformTransferList from './fiduciary/ClientPlatformTransferList.vue';
import Voila2List from './fiduciary/Voila2List.vue';
import FiduClient from './fiduciary/Client.vue';
import NewClient from './fiduciary/NewClient';
import ClientInfo from './fiduciary/ClientInfo';
import ClientCoda from './fiduciary/ClientCoda';
import ClientSoda from './fiduciary/ClientSoda';
import ClientVoila2 from './fiduciary/ClientVoila2.vue';
import ClientCaro from './fiduciary/ClientCaro';
import Services from './fiduciary/Services.vue';
import FiduciaryInfo from './fiduciary/FiduciaryInfo.vue';
import Archived from './fiduciary/Archived.vue';
import FiduciarySettings from './fiduciary/FiduciarySettings.vue';
import CreditCardStatementClientsList from './fiduciary/CreditCardStatementClientsList.vue';
import ConnectApiConsentPage from './fiduciary/ConnectApiConsentPage.vue';

import Reseller from './reseller/Reseller.vue';
import RslrFiduSearch from './reseller/FiduSearch.vue';
import RslrCodaSearch from './reseller/CodaSearch.vue';
import RslrSodaSearch from './reseller/SodaSearch.vue';

import Unsubscribe from './components/Unsubscribe.vue';

import OrganizationView from './organization/OrganizationView';
import OrganizationSettingsView from './organization/settings/OrganizationSettingsView';
import OrganizationUsersView from './organization/settings/OrganizationUsersView';

import store from './store';
import utils from './utils.js';

import Home from '@/views/Home';

import NotFoundView from '@/views/NotFoundView';

import { authGuard }  from '@/auth/authGuard';
import Login from '@/common/Login.vue';
import ForgotPassword from '@/common/ForgotPassword.vue';
import ResetPassword from '@/common/ResetPassword.vue';

/* catch all navigation failures to prevent stacktrace being emitted
 * when we redirect inside a route, which we use to handle logouts
 * See: https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378
 */

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location, onResolve, onReject) {
    if (onResolve || onReject) {
        return originalPush.call(this, location, onResolve, onReject);
    }
    return originalPush.call(this, location).catch((err) => {
        if (VueRouter.isNavigationFailure(err)) {
            // resolve err
            return err;
        }
        // rethrow error
        return Promise.reject(err);
    });
};

Vue.use(VueRouter);

const routes = [
    /* eslint indent:off, indent-legacy:off, object-property-newline:off */
    {
        path: '/',
        name: 'index',
        beforeEnter: authGuard,
    },
    {
        path: '/home',
        name: 'home',
        component: Home,
        beforeEnter: authGuard,
    },
    {
        path: '/login',
        name: 'login',
        component: Login,
    },
    {
        path: '/forgot-password',
        name: 'forgot-password',
        component: ForgotPassword,
    },
    {
        path: '/reset-password',
        name: 'reset-password',
        component: ResetPassword,
    },
    {
        path: '/verify/:token',
        name: 'email-verification',
        component: EmailVerificationWizard,
        meta: {
            title: 'Email Verification',
            entry: 'accept',
        },
    },
    {
        path: '/refuse-verification/:token',
        name: 'email-verification-refuse',
        component: EmailVerificationWizard,
        meta: {
            title: 'Email Verification',
            entry: 'refuse',
        },
    },
    {
        path: '/verify/restart/:token',
        name: 'restart-email-verification',
        component: RestartEmailVerification,
        meta: {
            title: 'Email Verification refresh',
        },
    },
    {
        path: '/sign-up/:invitationToken',
        name: 'signup',
        component: Signup,
        meta: { title: 'Signup' },
    },
    {
        path: '/change-password',
        name: 'changePW',
        component: ChangePassword,
        meta: { title: 'Change Password' },
        beforeEnter: authGuard,
    },
    {
        path: '/confirm-invitation/:token',
        name: 'confirmInvitation',
        component: ConfirmInvitation,
        beforeEnter: authGuard,
        meta: { title: 'Confirm User Invitation' },
    },
    {
        path: '/organization/:organizationId',
        name: 'organization',
        component: OrganizationView,
        beforeEnter: authGuard,
        meta: { restricted: 'organization' },
        children: [
            {
                path: 'settings',
                name: 'org-administration',
                component: OrganizationSettingsView,
                children: [
                    {
                        path: '',
                        name: 'organization-administration-users',
                        component: OrganizationUsersView,
                        beforeEnter: authGuard,
                        meta: {
                            title: 'Users',
                            restricted: 'organization',
                        },
                    },
                ],
                meta: {
                    title: 'Organization settings',
                    restricted: 'organization',
                },
            },
            {
                path: 'environment/:environmentId',
                name: 'environment',
                component: Fiduciary,
                beforeEnter: authGuard,
                meta: { title: 'Fiduciary', restricted: 'organization' },
                children: [
                    {
                        path: '',
                        name: 'dashboard',
                        component: Dashboard,
                        beforeEnter: authGuard,
                        meta: { title: 'Fiduciary Dashboard', restricted: 'organization' },
                    },
                    {
                        path: 'coda',
                        name: 'fidu-coda-search',
                        component: CodaSearch,
                        beforeEnter: authGuard,
                        meta: { title: 'Coda Search', restricted: 'organization' },
                    },
                    {
                        path: 'soda',
                        name: 'fidu-soda-search',
                        component: SodaSearch,
                        beforeEnter: authGuard,
                        meta: { title: 'Soda Search', restricted: 'organization' },
                    },
                    {
                        path: 'purchases',
                        name: 'fidu-purchase-search',
                        component: Voila2List,
                        beforeEnter: authGuard,
                        meta: { title: 'Purchase Search', restricted: 'organization' },
                    },
                    {
                        path: 'caro',
                        name: 'fidu-ccs-clients-list',
                        component: CreditCardStatementClientsList,
                        beforeEnter: authGuard,
                        meta: { title: 'Credit card statements', restricted: 'organization' },
                    },
                    {
                        path: 'contracts',
                        name: 'fidu-services-root',
                        component: Services,
                        beforeEnter: authGuard,
                        meta: { title: 'Contracts & Services', restricted: 'organization' },
                    },
                    {
                        path: 'archived/:type/:uid/',
                        name: 'archived',
                        component: Archived,
                        beforeEnter: authGuard,
                        meta: { title: 'Contracts & Services', restricted: 'organization' },
                    },
                    {
                        path: 'clients',
                        name: 'fidu-client-search',
                        component: ClientSearch,
                        beforeEnter: authGuard,
                        meta: { title: 'Client Search', restricted: 'organization' },
                    },
                    {
                        path: 'clients/nopurchases',
                        name: 'fidu-client-search-no-purchases',
                        component: ClientSearch,
                        beforeEnter: authGuard,
                        meta: { title: 'Client Search', restricted: 'organization' },
                    },
                    {
                        path: 'clients/transfers',
                        name: 'fidu-client-transfer-lists',
                        component: ClientTransfer,
                        beforeEnter: authGuard,
                        meta: { title: 'Client Transfer', restricted: 'organization' },
                        children: [
                            {
                                path: '',
                                name: 'fidu-client-regular-transfer-list',
                                component: ClientRegularTransferList,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Client Regular Transfer List', restricted: 'organization',
                                },
                            },
                            {
                                path: 'platform',
                                name: 'fidu-client-platform-transfer-list',
                                component: ClientPlatformTransferList,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Client Platform Transfer List', restricted: 'organization',
                                },
                            },
                        ],
                    },
                    {
                        path: 'info',
                        name: 'fidu-info',
                        component: FiduciaryInfo,
                        beforeEnter: authGuard,
                        meta: { title: 'Fiduciary Info', restricted: 'organization' },
                    },
                    {
                        path: 'client',
                        name: 'fidu-client-root',
                        component: FiduClient,
                        beforeEnter: authGuard,
                        meta: { title: 'Fiduciary Client', restricted: 'organization' },
                    },
                    {
                        path: 'client/new',
                        name: 'fidu-client-new',
                        component: NewClient,
                        beforeEnter: authGuard,
                        meta: { title: 'New Fiduciary Client', restricted: 'organization' },
                    },
                    {
                        path: 'client/:uid/',
                        component: FiduClient,
                        beforeEnter: authGuard,
                        name: 'client-detail-page',
                        meta: {
                            title: 'Fiduciary Client',
                            restricted: 'organization',
                        },
                        props: (route) => ({
                            routeName: route.name,
                            clientId: route.params.uid,
                        }),
                        children: [
                            {
                                path: '',
                                name: 'fidu-client-uid-info',
                                component: ClientInfo,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Fiduciary Client Infos',
                                    restricted: 'organization',
                                },
                            },
                            {
                                path: 'coda',
                                name: 'fidu-client-uid-coda',
                                component: ClientCoda,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Fiduciary Client Coda',
                                    restricted: 'organization',
                                },
                            },
                            {
                                path: 'soda',
                                name: 'fidu-client-uid-soda',
                                component: ClientSoda,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Fiduciary Client Soda',
                                    restricted: 'organization',
                                },
                            },
                            {
                                path: 'voila',
                                name: 'fidu-client-uid-voila',
                                component: ClientVoila2,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Fiduciary Client VOILA',
                                    restricted: 'organization',
                                },
                            },
                            {
                                path: 'caro',
                                name: 'fidu-client-uid-credit-card-statement',
                                component: ClientCaro,
                                beforeEnter: authGuard,
                                meta: {
                                    title: 'Fiduciary Client Credit Card Statement',
                                    restricted: 'organization',
                                },
                            },
                        ],
                    },
                    {
                        path: 'settings',
                        name: 'fidu-settings',
                        component: FiduciarySettings,
                        beforeEnter: authGuard,
                        meta: { title: 'Fiduciary settings', restricted: 'organization' },
                    },
                ],
            },
        ],
    },
    {
        path: '/fiduciary/connect-api-consent',
        name: 'connect-api-consent',
        component: ConnectApiConsentPage,
        beforeEnter: authGuard,
        meta: { title: 'Connect Api consent' },
    },
    {
        path: '/reseller/:resellerId',
        name: 'reseller',
        component: Reseller,
        meta: { title: 'Reseller', restricted: 'reseller' },
        beforeEnter: authGuard,
        children: [
            {
                path: 'fiduciaries',
                name: 'rslr-fidu-search',
                component: RslrFiduSearch,
                beforeEnter: authGuard,
                meta: { title: 'Fiduciaries', restricted: 'reseller' },
            },
            {
                path: 'coda',
                name: 'rslr-coda-search',
                component: RslrCodaSearch,
                beforeEnter: authGuard,
                meta: { title: 'Coda Search', restricted: 'reseller' },
            },
            {
                path: 'soda',
                name: 'rslr-soda-search',
                component: RslrSodaSearch,
                beforeEnter: authGuard,
                meta: { title: 'Soda Search', restricted: 'reseller' },
            },
        ],
    },
    {
        path: '/unsubscribe',
        name: 'unsubscribe',
        component: Unsubscribe,
    },
    // todo: create 404 page
    { path: '*', name: '404', component: NotFoundView },
    { path: '/maintenance', component: MaintenancePage },
];

const router = new VueRouter({
    routes,
});

router.routes = routes; // for easier access to routes definition

/* detect query params to enable flags */
router.beforeEach((to, from, next) => {
    if (to.query.flag) {
        for (let flag of to.query.flag.split(',')) {
            store.commit('setFlag', flag);
        }
    }
    next();
});

router.beforeEach((to, from, next) => {
    if (store.state.maintenance && to.fullPath !== '/maintenance') {
        next('/maintenance');
    } else {
        next();
    }
});

/* -- Scrolling -- */

router.afterEach((to, from) => {
    if (!(to.name === 'fidu-client-uid-info')) {
        utils.scrollTop();
    }
});

export default router;
