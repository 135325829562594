<script>
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import fr from 'vue2-datepicker/locale/fr';
import en from 'vue2-datepicker/locale/en';
import nl from 'vue2-datepicker/locale/nl';
import Loader from '@/loader';
import Modal from '@/components/Modal';
import CustomSwitch from '@/components/Switch';
import SwitchOption from '@/components/SwitchOption';
import FormField from '@/components/FormField';
import utils from '../../utils';

export default {
    props: {
        openAtLoad: {
            type: Boolean,
            default: false,
        },
        clientV2: {
            type: Object,
            required: true,
        },
        bankAccounts: {
            type: Array,
            required: true,
        },
    },
    components: {
        Modal,
        DatePicker,
        CustomSwitch,
        SwitchOption,
        FormField,
    },
    data ()  {
        return {
            modalOpened: false,
            saving: false,
            advanced: false,
            simpleData: '',
            advancedData: [],
            firstDate: '',
            lastDate: '',
            codaRanges: [],
        };
    },
    computed: {
        lang () {
            let lang;
            switch (this.$store.state.i18n.locale) {
                case 'fr_FR':
                    lang = fr;
                    break;
                case 'nl_BE':
                    lang = nl;
                    break;
                case 'en_US':
                    lang = en;
                    break;
            }

            return lang;
        },
        eligibleBankAccounts () {
            return this.bankAccounts.filter((ba) => ba.state === 'active');
        },
        bankAccountsWithAvailableFiles () {
            return this.advancedData.filter((d) => {
                return this.hasAvailableFilesForIban(d.iban);
            });
        },
        hasAvailableFiles () {
            return this.codaRanges.length > 0 && this.firstDate && this.lastDate;
        },
    },
    async mounted () {
        this.eligibleBankAccounts.forEach(account => {
            this.advancedData.push({
                iban: account.iban,
                bankName: account.bankName,
                method: 'date',
                date: '',
                index: {
                    start: '',
                    end: '',
                },
                active: false,
            });
        });
        if (this.openAtLoad) {
            await this.openModal();
        }
    },
    methods: {
        async openModal () {
            this.modalOpened = true;
            let ranges = await this.getCodaFileRange(this.clientV2.id, this.eligibleBankAccounts.map(ba => { return ba.iban; }));
            this.firstDate = ranges.firstDate;
            this.lastDate = ranges.lastDate;
            this.codaRanges = ranges.codaRanges;
        },
        closeModal () {
            this.modalOpened = false;
        },
        advancedClick () {
            this.advanced = !this.advanced;
        },
        hasAvailableFilesForIban (iban) {
            const range = this.codaRanges.find(range => range.bankAccount === iban);
            return !!(range && range.firstDate && range.lastDate);
        },
        nonAvailableDate (date, iban) {
            const range = this.codaRanges.find(range => range.bankAccount === iban);
            if (range && range.firstDate && range.lastDate) {
                let firstDate = (new Date(range.firstDate)).setHours(0);
                let lastDate = (new Date(range.lastDate)).setHours(0);
                return date >  lastDate || date < firstDate;
            } else {
                return date > this.lastDate || date < this.firstDate;
            }
        },
        nonAvailableYear (date) {
            return date.getFullYear() > new Date(this.lastDate).getFullYear() || date.getFullYear() < new Date(this.firstDate).getFullYear();
        },
        async resendCoda (input) {
            const { data } = await this.$apollo.mutate({
                mutation: gql`mutation resendCoda($input: ResendCodaInput) {
                    resendCoda(input: $input) {
                        errors { code, detail, source { pointer } }
                    }
                }`,
                variables: {
                    input: input,
                },
            });
            return data;
        },
        async submitForm () {
            if (this.saving) { return; }
            let valid = await this.$refs.form.validate();

            if (!valid) {
                return;
            }

            if (this.advanced && !(await this.advancedData.some(item => item.active))) {
                return;
            }

            Loader.start();
            this.saving = true;

            let payload = {
                clientId: this.clientV2.id,
                fiduciaryId: this.clientV2.fiduciaryId,
            };

            if (!this.advanced) {
                payload.period = {
                    startDate: this.simpleData[0],
                    endDate: this.simpleData[1],
                };
            } else {
                payload.bankAccounts = [];
                this.advancedData.forEach(item => {
                    if (item.active) {
                        if (item.method === 'date') {
                            payload.bankAccounts.push({
                                iban: item.iban,
                                period: {
                                    startDate: item.date[0],
                                    endDate: item.date[1],
                                },
                            });
                        } else {
                            payload.bankAccounts.push({
                                iban: item.iban,
                                index: {
                                    year: item.date,
                                    start: item.index.start,
                                    end: item.index.end,
                                },
                            });
                        }
                    }
                });
            }

            try {
                const data = await this.resendCoda(payload);
                if (data.resendCoda.errors) {
                    this.showApiErrors(data.resendCoda.errors);
                    Loader.stop();
                    this.saving = false;
                    return;
                }
                notify.success(this.$t('p-resend-coda-success'));
            } catch (err) {
                notify.error(this.$t('p-resend-coda-error'));
            }

            Loader.stop();
            this.saving = false;
            this.closeModal();
        },
        showApiErrors (errors) {
            if (!this.advanced) {
                let nocodas = errors.filter((err) => err.detail.includes('No CodaFile in range'));
                if (nocodas.length > 0) {
                    this.$refs.simpledatevalidation.applyResult({
                        errors: ['err-no-coda-in-range'],
                        valid: false,
                    });
                    notify.error(this.$t('err-no-coda-in-range'));
                } else {
                    notify.error(this.$t('p-resend-coda-error'));
                }
            } else {
                let nocodas = errors.filter((err) => err.detail.includes('No CodaFile in range'));
                if (nocodas.length > 0) {
                    for (const err of nocodas) {
                        const bankaccount = err.detail.split(':')[0];
                        const validator = this.$refs[`validation-${bankaccount}`][0];
                        if (validator) {
                            validator.applyResult({
                                errors: ['err-no-coda-in-range'],
                                valid: false,
                            });
                        }
                    }
                    notify.error(this.$t('err-no-coda-in-range'));
                } else {
                    notify.error(this.$t('p-resend-coda-error'));
                }
            }
        },
        async getCodaFileRange (clientId, bankAccounts) {
            const { data } = await this.$apollo.query({
                query: gql`query codaFileRange($clientId: String, $bankAccounts: [String]!) {
                    codaFileRange(clientId: $clientId, bankAccounts:$bankAccounts) {
                        bankAccount
                        firstDate
                        lastDate
                    }
                }`,
                variables: {
                    clientId: clientId,
                    bankAccounts: bankAccounts,
                },
            });
            if (data.codaFileRange && data.codaFileRange.length !== 0) {
                const firstDates = data.codaFileRange.map(firstDates => { return new Date(firstDates.firstDate).setHours(0); });
                const lastDates = data.codaFileRange.map(lastDates => { return new Date(lastDates.lastDate).setHours(0); });
                return {
                    firstDate: Math.min(...firstDates),
                    lastDate: Math.max(...lastDates),
                    codaRanges: data.codaFileRange,
                };
            } else {
                return {
                    firstDate: '',
                    lastDate: '',
                    codaRanges: [],
                };
            }
        },
        formatDate (value) {
            return utils.date2strYYYYMMDD(new Date(value));
        },
        getCodaFileRangeBankAccount (iban) {
            const firstDate = this.codaRanges.find(range => range.bankAccount === iban) ? this.codaRanges.find(range => range.bankAccount === iban).firstDate : '';
            const lastDate = this.codaRanges.find(range => range.bankAccount === iban) ? this.codaRanges.find(range => range.bankAccount === iban).lastDate : '';
            if (firstDate && lastDate) {
                return this.formatDate(firstDate) + ' / ' + this.formatDate(lastDate);
            }
        },
        fillDate (item = null) {
            if (item) {
                item.method = 'date';
                item.date = [];
                item.index = [];
                item.date[0] = this.codaRanges.find(range => range.bankAccount === item.iban).firstDate;
                item.date[1] = this.codaRanges.find(range => range.bankAccount === item.iban).lastDate;
                item.active = true;
            } else {
                this.simpleData = [];
                this.simpleData[0] = this.formatDate(new Date(this.firstDate));
                this.simpleData[1] = this.formatDate(new Date(this.lastDate));
            }
        },
        activateItem (item) {
            if (!item.active) {
                item.active = true;
            }
        },
    },
};
</script>

<template>
    <div>
        <button
            class='btn btn-default'
            @click='openModal()'
            id='resend-coda-button'
            :disabled='eligibleBankAccounts.length === 0'
        >
            {{ $t('btn-resend-coda') }}
        </button>
        <modal :show='modalOpened' larger>
            <validation-observer
                ref='form'
                tag='div'
                id='resend-coda-modal'
            >
                <div class='modal-header'>
                    <button type='button' class='close focus:outline-none' @click.prevent='closeModal'>
                        <span>&times;</span>
                    </button>
                    <h4 class='modal-title'>
                        {{ $t('ttl-popup-resend-coda') }}
                    </h4>
                </div>
                <div class='modal-body px-10 py-4'>
                    <div>
                        <header class='mb-6'>
                            <h2 class='text-lg'>
                                {{ $t('lbl-resend-coda-simple-date') }}
                            </h2>
                        </header>
                        <div>
                            <validation-provider
                                :rules='`${advanced ? "" : "required"}`'
                                v-slot='{ errors }'
                                vid='simpledate'
                                :name='$t("lbl-resend-coda-method-date")'
                                mode='lazy'
                                ref='simpledatevalidation'
                            >
                                <date-picker
                                    v-model='simpleData'
                                    range
                                    :disabled='advanced || !hasAvailableFiles'
                                    :placeholder='$t("placeholder-input-date")'
                                    :class='{"error": errors[0]}'
                                    :lang='lang'
                                    value-type='YYYY-MM-DD'
                                    format='DD-MM-YYYY'
                                    :disabled-date='nonAvailableDate'
                                    id='simple-date-range'
                                    @change='v => {
                                        if(!v[0]) {
                                            // required error fix
                                            simpleData = ""
                                        }
                                    }'
                                />
                                <a class='mt-3 flex' v-if='hasAvailableFiles' @click='fillDate()'>
                                    {{ $t('lbl-coda-range', { firstDate : formatDate(firstDate), lastDate: formatDate(lastDate)}, ) }}
                                </a>
                                <p class='mt-3' v-else>
                                    {{ $t('lbl-coda-range-platform-transfer-no-files') }}
                                </p>
                                <div v-if='errors[0]' class='mt-1 text-red-300'>
                                    {{ $t(errors[0]) }}
                                </div>
                            </validation-provider>
                        </div>
                    </div>
                    <div class='mt-6 text-blue-500' v-if='hasAvailableFiles'>
                        <button
                            class='bg-transparent border-none focus:outline-none'
                            id='advanced-button'
                            @click='advancedClick'
                        >
                            <span class='text-sm'>
                                <i class='fa fa-chevron-down' v-if='advanced'></i>
                                <i class='fa fa-chevron-right' v-else></i>
                            </span>
                            {{ $t('btn-resend-coda-advanced') }}
                        </button>
                        <div class='mt-6 border-b border-gray-200' v-if='advanced'>
                            <table class='min-w-full divide-y divide-gray-200'>
                                <thead>
                                    <tr>
                                        <th scope='col' class='relative pl-6 py-3'>
                                            <span class='sr-only'>Select</span>
                                        </th>
                                        <th scope='col' class='px-6 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                            {{ $t('th-resend-coda-bank-accounts') }}
                                        </th>
                                        <th scope='col' class='px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                            {{ $t('th-resend-coda-methods') }}
                                        </th>
                                        <th scope='col' class='relative px-6 py-3'>
                                            <span class='sr-only'>Edit</span>
                                        </th>
                                        <th scope='col' class='px-6 pr-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider'>
                                            <p>{{ $t('th-resend-coda-range') }}</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class='bg-white divide-y divide-gray-200' id='bankaccount-list'>
                                    <tr v-for='(item, i) in bankAccountsWithAvailableFiles'
                                        :key='i'
                                        :class='`${i % 2 ? "": "bg-grey-50 rounded-md"}`'
                                        ref='`advancedRows`'
                                        :id='`bankaccount-${i}`'
                                    >
                                        <td class='pl-6'>
                                            <input
                                                type='checkbox'
                                                v-model='item.active'
                                            >
                                        </td>
                                        <td class='px-6 py-4 whitespace-nowrap'>
                                            {{ item.iban }} ({{ item.bankName }})
                                        </td>
                                        <td class='px-6 py-4 whitespace-nowrap'>
                                            <div class='text-gray-900'>
                                                <custom-switch v-model='item.method'>
                                                    <switch-option id='date'>
                                                        {{ $t('lbl-resend-coda-method-date') }}
                                                    </switch-option>
                                                    <switch-option id='index'>
                                                        {{ $t('lbl-resend-coda-method-index') }}
                                                    </switch-option>
                                                </custom-switch>
                                            </div>
                                        </td>
                                        <td class='px-6 py-4 whitespace-nowrap w-full'>
                                            <div v-if='item.method === "date"'>
                                                <validation-provider
                                                    :rules='`${advanced && item.active ? "required" : ""}`'
                                                    v-slot='{ errors }'
                                                    :vid='`iban${item.iban}`'
                                                    :name='$t("lbl-resend-coda-method-date")'
                                                    mode='lazy'
                                                    :ref='`validation-${item.iban}`'
                                                >
                                                    <date-picker
                                                        v-model='item.date'
                                                        range
                                                        :placeholder='$t("placeholder-input-date")'
                                                        :class='{"error": errors[0]}'
                                                        :lang='lang'
                                                        value-type='YYYY-MM-DD'
                                                        format='DD-MM-YYYY'
                                                        :disabled-date='function(date) {
                                                            return nonAvailableDate(date, item.iban)
                                                        }'
                                                        :id='`daterange-${i}`'
                                                        @change='v => {
                                                            activateItem(item)
                                                            if(!v[0]) {
                                                                // required error fix
                                                                advancedData[i].date = ""
                                                            }
                                                        }'
                                                    />
                                                    <div v-if='errors[0]' class='error mt-1 text-red-300'>
                                                        {{ $t(errors[0]) }}
                                                    </div>
                                                </validation-provider>
                                            </div>
                                            <div v-else class='flex'>
                                                <validation-provider
                                                    :rules='`${advanced && item.active ? "required" : ""}`'
                                                    v-slot='{ errors }'
                                                    :vid='`year${i}`'
                                                    :name='$t("lbl-year")'
                                                    mode='lazy'
                                                    :ref='`validation-${item.iban}`'
                                                >
                                                    <date-picker
                                                        v-model='item.date'
                                                        type='year'
                                                        class='mr-3 w-24'
                                                        :placeholder='$t("placeholder-input-year")'
                                                        :class='{"error": errors[0]}'
                                                        :lang='lang'
                                                        value-type='YYYY'
                                                        :disabled-date='nonAvailableYear'
                                                        @change='v => {
                                                            activateItem(item)
                                                        }'
                                                    />
                                                    <div v-if='errors[0]' class='mt-1 text-red-300'>
                                                        {{ $t(errors[0]) }}
                                                    </div>
                                                </validation-provider>
                                                <form-field
                                                    v-model='item.index.start'
                                                    vid='date'
                                                    :placeholder='$t("placeholder-resend-coda-index-from")'
                                                    :rules='`${advanced && item.active ? "required" : ""}`'
                                                    :min-value='0'
                                                    :max-value='999'
                                                    :edit='true'
                                                    class='mr-3 w-full'
                                                    no-margin
                                                    mode='lazy'
                                                    type='number'
                                                    :required='advanced && item.active'
                                                    @input='v => {
                                                        activateItem(item);
                                                    }'
                                                />
                                                <form-field
                                                    v-model='item.index.end'
                                                    :placeholder='$t("placeholder-resend-coda-index-to")'
                                                    :rules='`${advanced && item.active ? "required" : ""}`'
                                                    :min-value='item.index.start ? parseInt(item.index.start) : 0'
                                                    :max-value='999'
                                                    class='w-full'
                                                    :edit='true'
                                                    no-margin
                                                    mode='lazy'
                                                    type='number'
                                                    :required='advanced && item.active'
                                                    @input='v => {
                                                        activateItem(item);
                                                    }'
                                                />
                                            </div>
                                        </td>
                                        <td class='px-6 py-4 whitespace-nowrap w-full'>
                                            <a @click='fillDate(item)'> {{ getCodaFileRangeBankAccount(item.iban) }} </a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div class='modal-footer'>
                    <button type='button' class='btn btn-default' @click.prevent='closeModal'>
                        {{ $t('btn-cancel') }}
                    </button>
                    <button type='button'
                            class='btn btn-primary'
                            id='submit'
                            :disabled='saving || !hasAvailableFiles || ( advanced && !advancedData.some(item => item.active))'
                            @click.prevent='submitForm()'
                    >
                        {{ $t('btn-resend-coda') }}
                    </button>
                </div>
            </validation-observer>
        </modal>
    </div>
</template>

<style lang='postcss'>
.mx-icon-left,
.mx-icon-right,
.mx-icon-double-left,
.mx-icon-double-right,
.mx-icon-double-left,
.mx-icon-double-right {}

.mx-icon-left:before,
.mx-icon-right:before,
.mx-icon-double-left:before,
.mx-icon-double-right:before,
.mx-icon-double-left:after,
.mx-icon-double-right:after {
  content: "";
  position: relative;
  top: -1px;
  display: inline-block;
  width: 10px;
  height: 10px;
  vertical-align: middle;
  border-style: solid;
  border-color: currentColor;
  border-width: 2px 0 0 2px;
  border-radius: 1px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transform-origin: center;
  transform-origin: center;
  -webkit-transform: rotate(-45deg) scale(0.7);
  transform: rotate(-45deg) scale(0.7);
}
.mx-icon-double-left:after {
  left: -4px;
}
.mx-icon-double-right:before {
  left: 4px;
}
.mx-icon-right:before,
.mx-icon-double-right:before,
.mx-icon-double-right:after {
  -webkit-transform: rotate(135deg) scale(0.7);
  transform: rotate(135deg) scale(0.7);
}
.mx-btn {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1;
  font-size: 14px;
  font-weight: 500;
  padding: 7px 15px;
  margin: 0;
  cursor: pointer;
  background-color: transparent;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  color: #73879c;
  white-space: nowrap;
}
.mx-btn:hover {
  border-color: #0D52FF;
  color: #0D52FF;
}
.mx-btn-text {
  border: 0;
  padding: 0 4px;
  text-align: left;
  line-height: inherit;
}
.mx-scrollbar {
  height: 100%;
}
.mx-scrollbar:hover .mx-scrollbar-track {
  opacity: 1;
}
.mx-scrollbar-wrap {
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.mx-scrollbar-track {
  position: absolute;
  top: 2px;
  right: 2px;
  bottom: 2px;
  width: 6px;
  z-index: 1;
  border-radius: 4px;
  opacity: 0;
  -webkit-transition: opacity 0.24s ease-out;
  transition: opacity 0.24s ease-out;
}
.mx-scrollbar-track .mx-scrollbar-thumb {
  position: absolute;
  width: 100%;
  height: 0;
  cursor: pointer;
  border-radius: inherit;
  background-color: rgba(144, 147, 153, 0.3);
  -webkit-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.mx-zoom-in-down-enter-active,
.mx-zoom-in-down-leave-active {
  opacity: 1;
  -webkit-transform: scaleY(1);
  transform: scaleY(1);
  -webkit-transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  transition: transform 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    opacity 0.3s cubic-bezier(0.23, 1, 0.32, 1),
    -webkit-transform 0.3s cubic-bezier(0.23, 1, 0.32, 1);
  -webkit-transform-origin: center top;
  transform-origin: center top;
}
.mx-zoom-in-down-enter,
.mx-zoom-in-down-leave-to {
  opacity: 0;
  -webkit-transform: scaleY(0);
  transform: scaleY(0);
}
.mx-datepicker {
  position: relative;
  display: inline-block;
  width: 210px;
}
.mx-datepicker svg {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}
.mx-datepicker-range {
  width: 320px;
}
.mx-datepicker-inline {
  width: auto;
}
.mx-input-wrapper {
  position: relative;
}
.mx-input-wrapper .mx-icon-clear {
  display: none;
}
.mx-input-wrapper:hover .mx-icon-clear {
  display: block;
}
.mx-input-wrapper:hover .mx-icon-clear + .mx-icon-calendar {
  display: none;
}
.mx-input {
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100%;
  height: 34px;
  padding: 6px 30px;
  padding-left: 10px;
  font-size: 14px;
  line-height: 1.4;
  color: #555;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}
.mx-input:hover,
.mx-input:focus {
  border-color: #409aff;
}
.mx-input:disabled,
.mx-input.disabled {
  color: #ccc;
  background-color: #f3f3f3;
  border-color: #ccc;
  cursor: not-allowed;
}
.mx-input:focus {
  outline: none;
}
.mx-input::-ms-clear {
  display: none;
}
.mx-icon-calendar,
.mx-icon-clear {
  position: absolute;
  top: 50%;
  right: 8px;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 16px;
  line-height: 1;
  color: rgba(0, 0, 0, 0.5);
  vertical-align: middle;
}
.mx-icon-clear {
  cursor: pointer;
}
.mx-icon-clear:hover {
  color: rgba(0, 0, 0, 0.8);
}
.mx-datepicker-main {
  color: #73879c;
  background-color: #fff;
  border: 1px solid #e8e8e8;
}
.mx-datepicker-popup {
  position: absolute;
  margin-top: 1px;
  margin-bottom: 1px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  z-index: 2001;
}
.mx-datepicker-sidebar {
  float: left;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 100px;
  padding: 6px;
  overflow: auto;
}
.mx-datepicker-sidebar + .mx-datepicker-content {
  margin-left: 100px;
  border-left: 1px solid #e8e8e8;
}
.mx-datepicker-body {
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.mx-btn-shortcut {
  display: block;
  padding: 0 6px;
  line-height: 24px;
}
.mx-range-wrapper {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
@media (max-width: 750px) {
  .mx-range-wrapper {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.mx-datepicker-header {
  padding: 6px 8px;
  border-bottom: 1px solid #e8e8e8;
}
.mx-datepicker-footer {
  padding: 6px 8px;
  text-align: right;
  border-top: 1px solid #e8e8e8;
}
.mx-calendar {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  width: 248px;
  padding: 6px 12px;
}
.mx-calendar + .mx-calendar {
  border-left: 1px solid #e8e8e8;
  display: none;
}
.mx-calendar-header,
.mx-time-header {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  height: 34px;
  line-height: 34px;
  text-align: center;
  overflow: hidden;
}
.mx-btn-icon-left,
.mx-btn-icon-double-left {
  float: left;
}
.mx-btn-icon-right,
.mx-btn-icon-double-right {
  float: right;
}
.mx-calendar-header-label {
  font-size: 14px;
}
.mx-calendar-decade-separator {
  margin: 0 2px;
}
.mx-calendar-decade-separator:after {
  content: "~";
}
.mx-calendar-content {
  position: relative;
  height: 224px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.mx-calendar-content .cell {
  cursor: pointer;
}
.mx-calendar-content .cell:hover {
  color: #73879c;
  background-color: #f3f9fe;
}
.mx-calendar-content .cell.active {
  color: #fff;
  background-color: #0D52FF;
}
.mx-calendar-content .cell.in-range,
.mx-calendar-content .cell.hover-in-range {
  color: #73879c;
  background-color: #dbedfb;
}
.mx-calendar-content .cell.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}
.mx-calendar-week-mode .mx-date-row {
  cursor: pointer;
}
.mx-calendar-week-mode .mx-date-row:hover {
  background-color: #f3f9fe;
}
.mx-calendar-week-mode .mx-date-row.mx-active-week {
  background-color: #dbedfb;
}
.mx-calendar-week-mode .mx-date-row .cell:hover {
  color: inherit;
  background-color: transparent;
}
.mx-calendar-week-mode .mx-date-row .cell.active {
  color: inherit;
  background-color: transparent;
}
.mx-week-number {
  opacity: 0.5;
}
.mx-table {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
  width: 100%;
  height: 100%;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
}
.mx-table th {
  padding: 0;
  font-weight: 500;
}
.mx-table td {
  padding: 0;
}
.mx-table-date td,
.mx-table-date th {
  height: 32px;
  font-size: 12px;
}
.mx-table-date .today {
  color: #0D52FF;
}
.mx-table-date .cell.not-current-month {
  color: #ccc;
  background: none;
}
.mx-time {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 224px;
  background: #fff;
}
.mx-time + .mx-time {
  border-left: 1px solid #e8e8e8;
}
.mx-calendar-time {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.mx-time-header {
  border-bottom: 1px solid #e8e8e8;
}
.mx-time-content {
  height: 224px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
}
.mx-time-columns {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.mx-time-column {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  position: relative;
  border-left: 1px solid #e8e8e8;
  text-align: center;
}
.mx-time-column:first-child {
  border-left: 0;
}
.mx-time-column .mx-time-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mx-time-column .mx-time-list::after {
  content: "";
  display: block;
  height: 192px;
}
.mx-time-column .mx-time-item {
  cursor: pointer;
  font-size: 12px;
  height: 32px;
  line-height: 32px;
}
.mx-time-column .mx-time-item:hover {
  color: #73879c;
  background-color: #f3f9fe;
}
.mx-time-column .mx-time-item.active {
  color: #0D52FF;
  background-color: transparent;
  font-weight: 700;
}
.mx-time-column .mx-time-item.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}
.mx-time-option {
  cursor: pointer;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
}
.mx-time-option:hover {
  color: #73879c;
  background-color: #f3f9fe;
}
.mx-time-option.active {
  color: #0D52FF;
  background-color: transparent;
  font-weight: 700;
}
.mx-time-option.disabled {
  cursor: not-allowed;
  color: #ccc;
  background-color: #f3f3f3;
}

.mx-datepicker-main {
  border-radius: 5px;
}

.mx-datepicker.error input {
  border-color: #e74c3c;
}
a {
  cursor: pointer;
}
</style>
