<script>
    export default {
        name: 'enterprise-num-input',
        props: {
            field: {type: String, default: ''},
            vid: {type: String, default: ''},
            value: {type: String, default: ''},
            edit: {type: Boolean, default: false},
            required: {type: Boolean, default: false},
        },
        data () {
            return {
                PLACEHOLDER: '0123456789',
                FORMAT:      'DDDDDDDDDD',
                content: this.value || '',
            };
        },
        computed: {
            placeholder () {
                if (this.content) {
                    return this.getPlaceholder(this.content);
                } else {
                    return this.PLACEHOLDER;
                }
            },
        },
        watch: {
            value: {
                deep: true,
                handler (val) {
                    this.content = val;
                    if (this.$refs.field) {
                        this.$refs.field.reset();
                    }
                },
            },
        },
        methods: {
            formatValue (input) {
                input = input.replace(/\s+/g, '');
                input = input.replace(/\./g, '');
                input = input.toUpperCase();
                input = input.split('');
                var res = '';
                for (var i = 0; i < this.FORMAT.length && input.length; i++) {
                    var F = this.FORMAT[i];
                    if (F === ' ') {
                        res += ' ';
                    } else if (F === 'D') {
                        if (input[0].match(/\d/)) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    } else {
                        if (input[0] === this.PLACEHOLDER[i]) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    }
                }
                return res.toUpperCase();
            },
            getPlaceholder (input) {
                var res = '';
                for (var i = 0; i < this.PLACEHOLDER.length; i++) {
                    if (i < input.length) {
                        res += ' ';
                    } else {
                        res += this.PLACEHOLDER[i];
                    }
                }
                return res;
            },
            exitField () {
                this.content = this.formatValue(this.content);
            },
            selectAll (event) {
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },
            handleInput () {
                this.content = this.formatValue(this.content);
                this.$emit('input', this.content);
            },
        },
    };
</script>

<template>
    <div>
        <label>{{ $t('lbl-enterpriseNumber') }}<span v-if='required && edit' class='text-blue-500'>*</span></label>
        <ValidationProvider v-if='edit'
                            v-slot='{ errors, failedRules }'
                            :rules='`enterpriseNumberFormat|${required ? "required" : ""}`'
                            ref='field'
                            :vid='vid'
                            slim
        >
            <section class='form-group' :class='{"has-error": errors[0]}'>
                <div class='cb-bai-input form-control relative'>
                    <input class='cb-bai-input-input w-full h-auto'
                           type='text'
                           :name='$t("lbl-enterpriseNumber")'
                           ref='input'
                           v-model='content'
                           @input='handleInput'
                           @focus='selectAll'
                           @blur='exitField'
                           :placeholder='placeholder'
                    >
                </div>
                <div v-if='errors[0]' class='error-message'>
                    <div v-if='failedRules.required'>
                        {{ $t('err-required-constraint') }}
                    </div>
                    <div v-else>
                        {{ errors[0] }}
                    </div>
                </div>
            </section>
        </ValidationProvider>
        <div class='cb-form-value' v-else>
            <p v-if='value'>
                {{ value }}
            </p>
            <p v-else>
                -
            </p>
        </div>
    </div>
</template>

<style scoped>
/* ----------------- *\
   *    IBAN INPUT     *
  \* ----------------- */

.cb-bai-input {
  min-width: 185px;
}

.cb-bai-input-input,
.cb-bai-input-format {
  padding: 0;
  margin: 0;
  display: inline-block;
  background: transparent;
  border: none;
  white-space: pre;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  z-index: 10;
}
.cb-bai-input-input:focus {
  outline: none;
  border: none;
}
.cb-bai-input-format {
  color: #cbcbcb;
  z-index: 0;
}

.cb-bai-status {
  display: inline-block;
  margin-right: 10px;
  padding: 7px 13px;
  background: rgba(103, 189, 37, 0.11);
  color: #4e8f1c;
  border-radius: 4px;
  vertical-align: middle;
}
.has-error .cb-bai-status {
  background: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

.cb-bai-status-icon {
  margin-right: 7px;
}

@media (max-width: 767px) {
  .cb-bai-status {
    display: block;
    margin-top: 10px;
    margin-right: 0px;
  }

  .cb-btn-tr {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0px 11px;
    border-radius: 5px;
    text-align: center;
  }
}
</style>
