<script>
import CodaStateReason from './CodaStateReason';
import Codastate from '@/components/Codastate';
import Dropdown from '@/components/Dropdown';
import Modal from '@/components/Modal.vue';
import notify from '@/notify';
import validate from '@/validate';
import { gql } from '@apollo/client/core';
import CodaMandateButtons from '@/components/CodaMandateButtons.vue';
import CodaDownload from '@/components/Codadownload';
import CodaTeletransmissionDownload from '@/components/CodaTeletransmissionDownload';
import InsightInfo from '@/components/InsightInfo.vue';
import { mapState } from 'vuex';

export default {
    props: {
        mandate: Object,
        selected: Boolean,
        noShadow: {
            type: Boolean,
            default: false,
        },
        animated: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        CodaStateReason,
        Codastate,
        Dropdown,
        Modal,
        CodaMandateButtons,
        CodaDownload,
        CodaTeletransmissionDownload,
        InsightInfo,
    },
    data () {
        return {
            displayedBankAccount: -1,
            // firstLook because in some cases we want to open by default some bank accounts accordeon
            // but then it should work as usual
            firstLook: true,
            bankAccountArchival: {
                showWarning: false,
                confirmed: false,
                account: null,
            },
            reason: '',
            codaArchival: {
                showWarning: false,
                confirmed: false,
                mandate: null,
            },
        };
    },
    computed: {
        bankaccountIban () {
            if (this.bankAccountArchival.account == null) {
                return '';
            } else {
                return this.bankAccountArchival.account.iban;
            }
        },
        isBelfiusOrBnp () {
            return this.mandate.bankId === 'GEBABEBB' || this.mandate.bankId === 'GKCCBEBB';
        },
        hasDownloadState () {
            const canDownloadStates = ['available-online', 'prepared', 'sent-client', 'problem'];
            return this.mandate.bank.name && canDownloadStates.includes(this.mandate.state);
        },
        filteredReminders () {
            let filteredReminders = [];
            if (this.mandate.reminders) {
                if (this.mandate.state === 'sent-client') {
                    filteredReminders = this.mandate.reminders.filter(e => e.type === 'coda-mandate-signature');
                } else if (
                        ['bank-procedure', 'sent-hq', 'active'].includes(this.mandate.state) &&
                        this.mandate.bankAccounts.some(ba => ba.flowState === 'pending-activation-by-client')
                    ) {
                    filteredReminders = this.mandate.reminders.filter(e => e.type === 'bank-account-activation');
                }
            }
            return filteredReminders;
        },
        hasAnyKbcCbcIncompleteBankProcedureProblem () {
            return this.currentClientProblems.some(p => p.type === 'kbcCbcIncompleteBankProcedure');
        },
        ...mapState(['currentClientProblems']),
    },
    methods: {
        stateLabel (flowState, state = null, mandateState = null) {
            // we do not want to display a state in these cases
            if ((
                mandateState === 'problem'
                ) || (
                flowState === 'draft' && mandateState === 'signed'
            )) {
                return null;
            } else {
                return 'lbl-ba-coda-state-' + flowState;
            }
        },
        type (type) {
            if (type === 'current') {
                return 'glyphicon-refresh';
            } else if (type === 'savings') {
                return 'glyphicon-piggy-bank';
            } else {
                return '';
            }
        },
        typeLabel (type) {
            return (type === 'current' || type === 'savings') ? this.$t(`lbl-account-${type}`) : '';
        },
        displayBankAccount (index, account) {
            if (
                this.displayedBankAccount !== index &&
                !this.displayedBankAccountAtFirstLook(account.state)
            ) {
                this.displayedBankAccount = index;
            } else {
                this.displayedBankAccount = -1;
            }
            this.firstLook = false;
        },
        displayedBankAccountAtFirstLook (state) {
            return state === 'pending' && this.firstLook;
        },
        accountClass (state) { return 'cb-bank-state-' + state; },
        getCodaMandates () {
            this.$emit('getCodaMandatesEvent');
        },
        canArchiveBankaccount (mandate, account) {
            return (
                ['signed', 'bank-procedure', 'sent-hq', 'active'].includes(mandate.state) &&
                account.state !== 'archived' &&
                mandate.bankAccounts.length - mandate.bankAccounts.filter(account => account.state === 'archived').length > 1
            );
        },
        doArchiveMandateWithWarning (mandate) {
            this.codaArchival.mandate = mandate;
            this.codaArchival.confirmed = false;
            this.codaArchival.showWarning = true;
        },
        cancelMandateArchive () {
            this.codaArchival.mandate = null;
            this.codaArchival.showWarning = false;
            this.codaArchival.confirmed = false;
            this.reason = '';
        },
        doArchiveBankaccountWithWarning (account) {
            this.bankAccountArchival.account = account;
            this.bankAccountArchival.confirmed = false;
            this.bankAccountArchival.showWarning = true;
        },
        cancelBankaccountArchive () {
            this.bankAccountArchival.showWarning = false;
            this.bankAccountArchival.confirmed = false;
            this.bankAccountArchival.account = null;
            this.reason = '';
        },
        doArchiveBankaccount () {
            this.bankAccountArchival.showWarning = false;
            this.archiveBankAccount(this.mandate.clientId, this.bankAccountArchival.account.id);
        },
        async updateBankMandateState (clientId, bankAccountId, state) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation ($input: UpdateBankMandateStateInput!) {
                        updateBankMandateState(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: clientId,
                            bankAccountId: bankAccountId,
                            state: state,
                        },
                    },
                });
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
        },
        async archiveBankAccount (clientId, bankAccountId) {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation ($input: ArchiveBankAccountInput!) {
                        archiveBankAccount(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: clientId,
                            bankAccountId: bankAccountId,
                        },
                    },
                });
                if (this.isBelfiusOrBnp && this.reason === true && (this.bankAccountArchival.account.bankMandateState === 'active' || this.bankAccountArchival.account.bankMandateState === 'pending')) {
                    this.updateBankMandateState(this.mandate.clientId, this.bankAccountArchival.account.id, 'stop-coda-todo');
                }
                notify.success(this.$t('p-archived-bank-account-success'));
                this.reason = '';
                this.getCodaMandates();
            } catch (e) {
                notify.error(this.$t('err-unknown'));
            }
        },
        async archiveCodaMandate () {
            const codaMandate = this.mandate;
            const bankAccounts = codaMandate.bankAccounts;

            this.codaArchival.showWarning = false;
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation ($input: ArchiveCodaMandateInput!) {
                        archiveCodaMandate(input: $input) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        input: {
                            clientId: codaMandate.clientId,
                            codaMandateId: this.codaArchival.mandate.id,
                        },
                    },
                });
                if (this.isBelfiusOrBnp && this.reason === true) {
                    bankAccounts.forEach(bankAccount => {
                        if (bankAccount.bankMandateState === 'active' || bankAccount.bankMandateState === 'pending') {
                            this.updateBankMandateState(codaMandate.clientId, bankAccount.id, 'stop-coda-todo');
                        }
                    });
                }
                notify.success(this.$t('p-archived-coda-success'));
                this.reason = '';
                this.getCodaMandates();
            } catch (err) {
                await validate.notifyErrors(err);
            }
        },
        kbcCbcIncompleteBankProcedureHighlight (account) {
            return (
                this.hasAnyKbcCbcIncompleteBankProcedureProblem &&
                ['KREDBEBB', 'CREGBEBB'].includes(account.bankId) &&
                account.state === 'pending' &&
                account.bankMandateState === 'pending'
            );
        },
    },
};
</script>

<template>
    <div class='coda-card' :class='{"no-shadow": noShadow, animated}'>
        <span v-if='selected' class='selected'>{{ $t("pill-manage") }}</span>
        <Modal :show='bankAccountArchival.showWarning'>
            <div class='modal-header alert-danger'>
                <button type='button' class='close' @click.prevent='cancelBankaccountArchive'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('h-confirm-archive') }}
                </h4>
            </div>
            <div class='modal-body'>
                <slot>
                    <p v-html='$t("p-confirm-archive-bank-account-a", {iban: bankaccountIban})'></p>
                </slot>

                <div v-if='isBelfiusOrBnp'>
                    <p>{{ $t('p-confirm-archive-coda-b') }}</p>
                    <p>
                        {{ $t('p-confirm-archive-coda-c') }}
                        <a target='_blank' :href='$t("p-confirm-archive-coda-link-a")'>Belfius</a> / <a target='_blank' :href='$t("p-confirm-archive-coda-link-b")'>BNP Paribas Fortis</a>).
                    </p>
                    <p-radio name='reason'
                             v-model='reason'
                             :value='true'
                             id='accept'
                    >
                        <span class='ml-2'>
                            {{ $t('p-confirm-archive-coda-stop') }}
                        </span>
                    </p-radio><br>
                    <p-radio name='reason'
                             v-model='reason'
                             :value='false'
                             id='decline'
                    >
                        <span class='ml-2'>
                            {{ $t('p-confirm-archive-coda-no-stop') }}
                        </span>
                    </p-radio>
                </div>
                <br>
                <div class='form-inline'>
                    <div class='form-group'>
                        <label>
                            <input v-model='bankAccountArchival.confirmed' type='checkbox'>
                            <span class='ml-2'>{{ $t('lbl-confirm-archive') }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='cancelBankaccountArchive'>
                    {{ $t('btn-cancel') }}
                </button>
                <button type='button'
                        class='btn btn-primary'
                        @click.prevent='doArchiveBankaccount'
                        :disabled='!bankAccountArchival.confirmed || (isBelfiusOrBnp && reason === "")'
                >
                    {{ $t('btn-archive') }}
                </button>
            </div>
        </Modal>
        <Modal :show='codaArchival.showWarning'
               id='archiveCodaMandateModal'
        >
            <div class='modal-header alert-danger'>
                <button type='button' class='close' @click.prevent='cancelMandateArchive'>
                    <span>&times;</span>
                </button>
                <h4 class='modal-title'>
                    {{ $t('h-confirm-archive') }}
                </h4>
            </div>
            <div class='modal-body'>
                <slot><p>{{ $t('p-confirm-archive-coda-a') }}</p></slot>
                <div v-if='isBelfiusOrBnp'>
                    <p>{{ $t('p-confirm-archive-coda-b') }}</p>
                    <p>
                        {{ $t('p-confirm-archive-coda-c') }}
                        <a target='_blank' :href='$t("p-confirm-archive-coda-link-a")'>Belfius</a> / <a target='_blank' :href='$t("p-confirm-archive-coda-link-b")'>BNP Paribas Fortis</a>).
                    </p>
                    <p-radio name='reason'
                             v-model='reason'
                             :value='true'
                             id='accept'
                    >
                        <span class='ml-2'>
                            {{ $t('p-confirm-archive-coda-stop') }}
                        </span>
                    </p-radio><br>
                    <p-radio name='reason'
                             v-model='reason'
                             :value='false'
                             id='decline'
                    >
                        <span class='ml-2'>
                            {{ $t('p-confirm-archive-coda-no-stop') }}
                        </span>
                    </p-radio>
                </div>
                <br>
                <div class='form-inline'>
                    <div class='form-group'>
                        <label>
                            <input v-model='codaArchival.confirmed' type='checkbox' id='confirmArchiveCheckbox'>
                            <span class='ml-2'>{{ $t('lbl-confirm-archive') }}</span>
                        </label>
                    </div>
                </div>
            </div>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='cancelMandateArchive'>
                    {{ $t('btn-cancel') }}
                </button>
                <button type='button'
                        class='btn btn-primary'
                        :disabled='!codaArchival.confirmed || (isBelfiusOrBnp && reason === "")'
                        @click='archiveCodaMandate'
                        id='confirmArchiveButton'
                >
                    {{ $t('btn-archive') }}
                </button>
            </div>
        </Modal>
        <div class='head'>
            <div>{{ mandate.bank.name }}</div>
            <div class='head__actions' v-if='mandate.state !== "archived"'>
                <dropdown tag='div'>
                    <template slot='toggle'>
                        {{ $t('lbl-coda-action') }}
                        <span class='menu-dot' :id='"codaCardActions_"+mandate.id'></span>
                    </template>
                    <template slot='list'>
                        <li>
                            <div class='btn btn-alt btn-xs'
                                 :class='{"disabled": mandate.state === "archived"}'
                                 @click='doArchiveMandateWithWarning(mandate)'
                                 :id='`codaCardArchiveCodaMandateButton_${mandate.id}`'
                            >
                                {{ $t('btn-archive') }}
                            </div>
                        </li>
                        <li v-if='hasDownloadState'>
                            <CodaDownload :mandate='mandate' />
                        </li>
                        <li v-if='hasDownloadState'>
                            <CodaTeletransmissionDownload :mandate='mandate' />
                        </li>
                    </template>
                </dropdown>
            </div>
        </div>
        <div class='status-n-date'>
            <Codastate :mandate='mandate' class='status' variant='labelized' />
            <div>{{ $t('coda-date-on') }} <span class='grey-date'>{{ mandate.stateDate | dateFormat }}</span></div>
        </div>
        <div class='reason'>
            <span class='fa fa-info-circle'></span>
            <CodaStateReason :mandate='mandate' multiline wide />
        </div>
        <div v-if='mandate.hasRedeliveryRequest' class='reason'>
            <span class='fa fa-info-circle'></span>
            <p class='pending-info'>
                {{ $t('lbl-pending-redelivery-request') }}
            </p>
        </div>
        <div class='reminders' v-if='filteredReminders.length'>
            <span class='bg-grey-300 px-4 py-1 rounded-full text-white text-md mb-2 inline-block'>
                <span v-if='filteredReminders.length === 1'>
                    {{ $t('lgnd-coda-num-reminder') }}
                </span>
                <span v-else>
                    {{ $t('lgnd-coda-num-reminders', { num: filteredReminders.length }) }}
                </span>
            </span>
            <div class='reminders-body'>
                <div v-for='(r, i) in filteredReminders' :key='i' class='m-6'>
                    {{ r.date }}
                    <span class='px-3 text-grey-200'>|</span>
                    <span v-if='r.targetEmail'>
                        {{ $t('lgnd-coda-reminder-sent', { target_email: r.targetEmail }) }}
                    </span>
                    <span v-else>
                        {{ $t('lgnd-coda-reminder-sent-post') }}
                    </span>
                </div>
            </div>
        </div>
        <div>
            <div v-for='(account, index) in mandate.bankAccounts' :key='index'>
                <InsightInfo
                    :has-problem='kbcCbcIncompleteBankProcedureHighlight(account)'
                    class='w-full mb-2'
                >
                    <div class='bank'>
                        <div class='bank-head' @click='displayBankAccount(index, account)'>
                            <div>
                                <span :class='`${accountClass(account.state)}`'>{{ account.iban }}</span>
                                <div class='cb-legend'>
                                    <span class='glyphicon bank-head__type-icon' :class='`${type(account.type)}`' aria-hidden='true'></span>
                                    <div class='cb-legend-body'>
                                        {{ typeLabel(account.type) }}
                                    </div>
                                </div>
                            </div>
                            <div class='bank-controls'>
                                <div class='bank-controls__open'>
                                    <span class='arrow' :class='`${displayedBankAccount === index ? "arrow--top" : ""}`'></span>
                                </div>

                                <div class='head__actions' v-if='canArchiveBankaccount(mandate, account)'>
                                    <dropdown tag='div'>
                                        <template slot='toggle'>
                                            {{ $t('lbl-coda-action') }}
                                            <span class='menu-dot'></span>
                                        </template>
                                        <template slot='list'>
                                            <li>
                                                <div class='btn btn-alt btn-xs'
                                                     :class='{"disabled": account.state === "archived"}'
                                                     @click.prevent='doArchiveBankaccountWithWarning(account)'
                                                >
                                                    {{ $t('btn-archive') }}
                                                </div>
                                            </li>
                                        </template>
                                    </dropdown>
                                </div>
                                <!--<div><span class='menu-dot'></span></div>-->
                            </div>
                        </div>
                        <div class='bank-body'
                             v-show='displayedBankAccount === index || displayedBankAccountAtFirstLook(account.state)'
                        >
                            <div
                                class='bank-status'
                                v-if='stateLabel(account.flowState, account.state, mandate.state)'
                                v-html='$t(stateLabel(account.flowState, account.state, mandate.state))'
                            >
                            </div>
                            <div class='bank-date' v-if='account.stateDate && stateLabel(account.flowState, account.state, mandate.state)'>
                                {{ $t('coda-date-on') }} <span class='grey-date'>{{ account.stateDate | dateFormat }}</span>
                            </div>
                            <div
                                v-if='!stateLabel(account.flowState, account.state, mandate.state)'
                                class='no-bank-state'
                                v-html='$t("lbl-ba-coda-state-no-bank-account")'
                            ></div>
                        </div>
                    </div>
                </InsightInfo>
            </div>
        </div>
        <div class='footer'>
            <div class='footer__downloads'>
                <CodaMandateButtons
                    :mandate='mandate'
                    :css-class='"footer__action"'
                    @refreshCodaMandates='getCodaMandates'
                    v-if='mandate.state !== "archived"'
                />
            </div>
        </div>
    </div>
</template>

<style lang='scss' scoped>
.coda-card {
    @apply bg-grey-50;
    width: 100%;
    border-radius: 5px;
    padding: 15px 20px;
    margin-bottom: 30px;
    position: relative;

    &.no-shadow {
        box-shadow: none;
    }

    &.animated {
        .selected {
            animation: appear .3s $ease-in-out forwards;
        }
        &.no-shadow {
            animation: no-shadow .3s $ease-in forwards;
        }
    }
}

.selected {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    font-size: .9em;
    padding: 3px 10px 1px;
    border-radius: 20px;
    transform: translate(-10%, -50%);
    background: $warning-color;
}

@keyframes appear {
    0% {
        opacity: 0;
        transform: translate(-10%, -100%);
    }
    100% {
        opacity: 1;
        transform: translate(-10%, -50%);
    }
}

@keyframes no-shadow {
    0% {
        box-shadow: 0 0 30px $color-grey;
    }
    100% {
        box-shadow: none;
    }
}

.arrow {
    width: 15px;
    height: 15px;
    flex-shrink: 0;
    background: url('~@/assets/arrow.svg') center center;
    background-size: 100% 100%;
    display: inline-block;

    &--right {
        transform: rotate(-90deg);
    }

    &--top {
        transform: rotate(-180deg);
    }
}

.menu-dot {
    width: 15px;
    height: 15px;
    background: url('~@/assets/menu_dot.svg') center center;
    background-size: 100% 100%;
    display: inline-block;
}

.head,
.status-n-date {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}

.head__actions {
    display: flex;
    align-items: center;
    cursor: pointer;

    .menu-dot {
        margin-left: 10px;
    }
}

.reason {
    display: flex;
    margin-bottom: 15px;
    .fa {
        color: $color-grey-dark;
        margin-top: 4px;
        margin-right: 15px;
    }
}

.bank-head,
.bank-body {
    display: flex;
    justify-content: space-between;
}

.bank-head {
    cursor: pointer;
    align-items: center;

    &__type-icon {
        margin-left: 10px;
    }
}

.bank-body {
    font-size: 1rem;
    margin-top: 5px;
}

.bank-date {
    min-width: 100px;
}

.bank-controls {
    display: flex;
    font-size: 0;

    > div {
        display: flex;
        align-items: center;
        padding: 5px 0 5px 5px;
    }
}

.bank-controls__open {
    padding: 5px;
}

.head__actions {
    padding: 5px 0 5px 5px;
}

.grey-date {
    color: $color-grey-medium;
    margin-left: 5px;
}

.footer {
    display: flex;

    &__action {
        margin-left: auto;
    }
}

.footer__downloads {
    margin-left: auto;
}
// bank states

.cb-bank-state-new,
.cb-bank-state-pending,
.cb-bank-state-draft,
.cb-bank-state-testing,
.cb-bank-state-blocked,
.cb-bank-state-archived,
.cb-tooltip,
.cb-bank-state-non-zoomit-bank {
    color: $primary-color-dark;
}

.cb-bank-state-active,
.cb-bank-state-registered {
    color: $validation-color;
}

.cb-bank-state-registration-failed {
    color: $warning-color;
}

.cb-bank-state-problem {
    color: $error-color;
}

.cb-bank-state-archived,
.cb-bank-state-non-zoomit-bank {
    text-decoration: line-through;
}

.no-bank-state {
    color: $color-grey-medium;
    font-style: italic;
}
</style>

<style lang="postcss" scoped>
.reminders {
    @apply cursor-pointer mb-3 relative text-xl;
}

.reminders:hover .reminders-body {
    @apply opacity-100 translate-y-0 pointer-events-auto;
}

.reminders-body {
    @apply opacity-0 absolute bg-white shadow-lg rounded-md left-0 z-10 transform -translate-y-2 border border-solid border-grey-200;
    @apply pointer-events-none whitespace-nowrap min-w-full;
    @apply transition duration-100 ease-in-out;
}
.pending-info {
    font-size: 1.2rem;
}

</style>
