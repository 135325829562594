<script>
    import vt from 'vue-types';

    import Selector from './Selector.vue';
    import Loader from '../loader.js';
    import { authFetch as fetch } from '../api/fetch.js';
    import download from 'downloadjs';
    import notify from '../notify.js';

    export default {
        name: 'exporter',
        props: {
            model: vt.string.isRequired,
            fiduciaryId: vt.string.isRequired,
        },
        components: {
            'selector': Selector,
        },
        data () {
            return {
                open: false,
                format: {
                    selected: this.getSavedFormat(),
                    options: [
                        { value: 'csv', label: 'CSV' },
                        { value: 'xls', label: 'Excel (.xls)' },
                        { value: 'xlsx', label: 'Excel (.xlsx)' },
                    ],
                },
                dropdownOpened: false,
            };
        },
        methods: {
            getSavedFormat () {
                if (localStorage['cb-export-format'] in {'csv': '', 'xls': '', 'xlsx': ''}) {
                    return localStorage['cb-export-format'];
                } else {
                    return 'csv';
                }
            },
            async downloadFile (model, format, fiduciaryId) {
                var mimeTypes = {
                    'xlsx': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                    'csv': 'text/csv',
                    'xls': 'application/vnd.ms-excel',
                };

                let url = {
                    'clients': `bff/api/exports/clients?fiduciaryId=${fiduciaryId}`,
                    'bank-accounts': `bff/api/exports/bank-accounts?fiduciaryId=${fiduciaryId}`,
                    'coda-mandates': `bff/api/exports/coda-mandates?fiduciaryId=${fiduciaryId}`,
                    'soda-mandates': `bff/api/exports/soda-mandates?fiduciaryId=${fiduciaryId}`,
                }[model];

                let res = await fetch(
                    url,
                    {
                        method: 'GET',
                        headers: {
                            'Accept': mimeTypes[format] || 'text/csv',
                            'Content-Type': '',
                        },
                    },
                );
                if (res.status !== 200) {
                    throw Error(res.statusText);
                }
                let blob = await res.blob();

                return download(blob, `${model}.${format}`);
            },
            async doExport () {
                localStorage['cb-export-format'] = this.format.selected;
                let loadId = Loader.start();
                try {
                    await this.downloadFile(
                            this.model,
                            this.format.selected,
                            this.fiduciaryId,
                        );
                } catch {
                    notify.error(this.$t('err-export-failed'));
                }
                Loader.stop(loadId);
            },
        },
    };
</script>
<template>
    <div class='dropdown inline'
         :class='{"open" : dropdownOpened}'
    >
        <button class='btn btn-alt btn-sm dropdown-toggle'
                type='button'
                data-toggle='dropdown'
                @click='dropdownOpened = !dropdownOpened'
        >
            <i class='fa fa-file-code-o'></i>
            <slot>Export Data</slot>
            <span class='caret'></span>
        </button>
        <ul class='dropdown-menu'>
            <li class='group'>
                <form>
                    <label>{{ $t('lbl-file-format') }}</label>
                    <selector :model='format' />
                </form>
            </li>
            <li role='separator' class='divider'></li>
            <li class='text-center group'>
                <button class='btn btn-primary btn-sm'
                        type='button'
                        @click='doExport'
                >
                    <i class='fa fa-download'></i>
                    <span>{{ $t('btn-download') }}</span>
                </button>
            </li>
        </ul>
    </div>
</template>
