<script>
    import vt from 'vue-types';
    import bus from '../bus.js';

    export default {
        props: {
            value: vt.any.isRequired,
            field: vt.string,
            placeholder: vt.string,
            format: vt.string,
            upperCase: Boolean,
            label: vt.string,
            validator: Function,
            fullwidth: Boolean,
            required: {
                type: Boolean,
                default: false,
            },
            autocomplete: {
                type: String,
                default: 'on',
            },
            type: {
                type: String,
                default: 'text',
            },
            mode: {
                type: String,
                default: 'eager',
            },
            rules: {
                type: String,
                default: '',
            },
            textSize: {
                type: String,
                // it uses tailwind sizing
                default: '2xl',
            },
            hiddenRules: {
                type: Array,
                default: () => [],
            },
        },
        data () {
            return {
                showPassword: false,
            };
        },
        computed: {
            placeholderDisplay () {
                return this.getPlaceholder(this.value);
            },
            validation () {
                if (this.validator) {
                    return this.validator(this.value);
                } else {
                    return {
                        state: 'incomplete',
                        message: '',
                    };
                }
            },
            hasErrors () {
                return this.validation.state === 'error';
            },
            errorMessage () {
                if (this.hasErrors) {
                    if (this.validation.state === 'error') {
                        return this.$t(this.validation.message);
                    } else {
                        return this.$refs.validation.setErrors([this.field]);
                    }
                }
                return '';
            },
            ruleType () {
                // some of the types are not valid veevalidated types
                if (['text', 'boolean', 'select', 'textarea'].includes(this.type)) {
                    return '';
                } else {
                    return `|${this.type}`;
                }
            },
            ruleRequired () {
                return this.required ? 'required' : '';
            },
            validated () {
                return !this.hasErrors && (this.$refs.validation && this.$refs.validation.errors.length === 0);
            },
        },
        mounted () {
            bus.$on('validate', this.validate);
        },
        beforeDestroy () {
            bus.$off('validate', this.validate);
        },
        methods: {
            formatValue (input) {
                if (!this.format) {
                    return input;
                }

                input = input.replace(/\s+/g, '');
                input = input.replace(/\./g, '');
                input = input.toUpperCase();
                input = input.split('');

                var res = '';
                for (var i = 0; i < this.format.length && input.length; i++) {
                    var F = this.format[i];
                    if (F === ' ') {
                        res += ' ';
                    } else if (F === 'D') {
                        if (input[0].match(/\d/)) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    } else {
                        if (input[0] === this.placeholder[i]) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    }
                }
                return res.toUpperCase();
            },
            getPlaceholder (input) {
                if (!this.placeholder) {
                    return '';
                }

                var res = '';
                for (var i = 0; i < this.placeholder.length; i++) {
                    if (i < input.length) {
                        res += ' ';
                    } else {
                        res += this.placeholder[i];
                    }
                }
                return res;
            },
            validate () {
                this.validateFormat(this.value);
            },
            validateFormat (value) {
                if (this.format) {
                    if (value.length && value.length !== this.format.length) {
                        this.$refs.validation.validate().then(() => {
                            this.$refs.validation.setErrors([this.$t('val-bad-format')]);
                        });
                    } else {
                        this.$refs.validation.reset();
                    }
                }
            },
            updateValue (value) {
                var res = this.formatValue(value);
                this.$refs.input.value = res;
                this.$emit('input', res);
            },
            fix1PasswordFilled () {
                /**
                 * Fix to remove background color added by 1Password extension when field is filled.
                 *
                 * Keep the attribute, 1Password uses it to detect the input is filled.
                 * Set the attribute to a value not used by 1Password to remove the background color.
                 * Known values used by 1Password: "light", "dark".
                 */
                if (this.$refs.input.hasAttribute('data-com-onepassword-filled')) {
                    this.$refs.input.setAttribute('data-com-onepassword-filled', 'none');
                }
            },
            exitField () {
                this.fix1PasswordFilled();

                // Validate and format value:
                const res = this.formatValue(this.value);
                this.$refs.input.value = res;
                this.validateFormat(res);
            },
        },
    };
</script>

<template>
    <ValidationProvider :rules='`${ruleRequired}${ruleType}|${rules}`'
                        slim
                        vid='validationId'
                        ref='validation'
                        tag='div'
                        :mode='mode'
                        v-slot='{ errors, failedRules }'
                        :class='{fullwidth: $props.fullwidth}'
    >
        <div class='friendly-input-box' :class='{"has-error": hasErrors, validated: validated, fullwidth: $props.fullwidth}'>
            <label v-if='label'>{{ $t(label) }}</label>
            <div class='friendly-input' :class='`text-${textSize}`'>
                <input class='friendly-input-input'
                       :type='$props.type === "password" && showPassword ? "text": $props.type'
                       :name='field'
                       :autocomplete='$props.autocomplete'
                       ref='input'
                       :value='value'
                       @input='updateValue($event.target.value)'
                       @blur='exitField'
                       :placeholder='placeholder'
                >
                <div class='friendly-input-format'>
                    <span>{{ placeholderDisplay }}</span>
                </div>
                <span v-if='$props.type === "password"' class='fa fa-eye text-blue-400 text-xl p-3 m-0 flex items-center cursor-pointer' @click='showPassword = !showPassword' :class='{ "fa-eye-slash": showPassword }'></span>
            </div>
            <div class='friendly-input-errors' v-if='hasErrors'>
                {{ errorMessage }}
            </div>
            <div v-for='(error, index) in errors' :key='index'>
                <div v-if='!Object.values(failedRules).includes(error)' class='friendly-input-errors'>
                    {{ error }}
                </div>
            </div>
            <span v-for='(rule, key) in failedRules' :key='key'>
                <div v-if='!hiddenRules.includes(key)' class='friendly-input-errors'>
                    <span v-if='key === "required"'>
                        {{ $t('err-required-constraint') }}
                    </span>
                    <span v-else>
                        {{ rule }}
                    </span>
                </div>
            </span>
        </div>
    </ValidationProvider>
</template>

<style scoped>

.friendly-input-box {
    display: inline-block;
    text-align: left;
    position: relative;
    min-width: 300px;

    &.fullwidth {
        width: 100%;
    }
}
label {
    @apply text-blue-400;
    margin-left: 8px;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    transition: color linear 100ms;
}
.friendly-input-errors {
    @apply text-red-300 ml-2 inline-block font-bold text-sm transition-all mt-1;
}
.friendly-input {
    @apply border-solid border-blue-400;
    border-width: 3px;
    border-radius: 5px;
    height: 44px;
    transition: border-color linear 100ms;
    display: flex;
    position: relative;
}

.friendly-input-input {
    width: 100%;
}

.friendly-input-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    @apply text-blue-200;
    opacity: 1; /* Firefox */
}

.friendly-input-input:-ms-input-placeholder { /* Internet Explorer 10-11 */
    @apply text-blue-200;
}

.friendly-input-input::-ms-input-placeholder { /* Microsoft Edge */
    @apply text-blue-200;
}

.friendly-input-format {
    position: absolute;
    display: flex;
    align-items: center;
}

.friendly-input-input,
.friendly-input-format {
    @apply text-blue-300;
    padding: 0;
    margin: 0;
    padding-left: 13px;
    border: none;
    outline: none;
    width: calc(100% - 26px);
    font-family: 'Roboto', sans-serif;
    white-space: pre;
    line-height: 22px;
    background: transparent;
    transition: color linear 100ms;
}
.friendly-input-format {
    color: #CCC;
    z-index: -1;
    top: 8px;
}
.validated .friendly-input-input {
    color: #3dcc64;
}
.validated label {
    color: #3dcc64;
}
.validated .friendly-input {
    border-color: #3dcc64;
}
.has-error label {
    color: red;
}
.has-error .friendly-input {
    border-color: red;
}
.has-error .friendly-input-input {
    color: red;
}
</style>
