<script>
import clickOutside from '@/directives/click-outside';

export default {
    name: 'dropdown-v2',
    props: {
        icon: {
            type: String,
            default: 'chevron-down',
        },
        name: String,
        defaultValue: String,
        value: String || Array,
        nullable: {
            type: Boolean,
            default: false,
        },
        direction: {
            type: String,
            default: 'left',
        },
        buttonSmall: {
            type: Boolean,
            default: false,
        },
        semiTransparent: {
            type: Boolean,
            default: false,
        },
        transparent: {
            type: Boolean,
            default: false,
        },
        noSelected: {
            type: Boolean,
            default: false,
        },
        inverted: {
            type: Boolean,
            default: false,
        },
        whiteName: {
            type: Boolean,
            default: false,
        },
        accordion: {
            type: Boolean,
            default: false,
        },
        noMargins: {
            type: Boolean,
            default: false,
        },
        buttonExtraClasses: {
            type: String,
            default: () => '',
        },
        menuExtraClasses: {
            type: String,
            default: () => '',
        },
    },
    directives: {
        clickOutside: clickOutside,
    },
    data () {
        return {
            menuOpened: false,
            selectedHtml: false,
        };
    },
    computed: {
        options () {
            return this.$slots.default;
        },
        selected: {
            get: function () {
                return this.value;
            },
            set: function (newValue) {
                this.updateSelectedHtml(newValue);
                this.$emit('input', newValue);
            },
        },
    },
    watch: {
        '$store.state.i18n.locale': function () {
            this.updateSelectedHtml(this.selected);
        },
        value: function () {
            this.updateSelectedHtml(this.selected);
        },
    },
    created () {
        this.$on('activate', this.change);
        if (this.value) {
            this.selected = this.value;
        }
    },
    methods: {
        updateSelectedHtml (value) {
            this.options.map(e => {
                if (e.componentOptions && e.componentOptions.propsData.id === value) {
                    this.$nextTick(() => {
                        this.selectedHtml = e.elm.outerHTML;
                    });
                }
            });
        },
        toggle () {
            this.menuOpened = !this.menuOpened;
        },
        close () {
            this.menuOpened = false;
        },
        change (selected) {
            if (this.selected !== selected) {
                this.$emit('changed', selected);
                this.selected = selected;
            }
            this.close();
        },
        reset () {
            this.change(this.defaultValue ? this.defaultValue : null);
        },
    },
};
</script>

<template>
    <div class='dropdown-v2' v-click-outside='close'>
        <div
            class='dropdown-v2-button'
            :class='[{
                "dropdown-v2-button-small": buttonSmall,
                "semi-transparent": semiTransparent,
                "transparent": transparent,
                "whiteName": whiteName,
                "accordion": accordion,
                "noMargins": noMargins,
                menuOpened
            }]'
            @click='toggle'
        >
            <span v-if='name'>{{ name }}</span>
            <span class='flex items-center' v-if='selected'>
                <span class='separator mr-2' :class='{ "semi-transparent": semiTransparent }' v-if='name && !noSelected'>|</span>
                <div :class='[buttonExtraClasses]' v-html='selectedHtml' v-if='!noSelected'></div>
                <div v-if='nullable' class='ml-2 text-grey-300 cursor-pointer hover:text-blue-300' @click='reset'><i class='fa fa-times'></i></div>
            </span>
            <span class='dropdown-v2-icon' :class='[{"dropdown-v2-icon__active": menuOpened, "dropdown-v2-icon--upper": icon === "chevron-down"}]' v-if='icon'>
                <i class='fa' :class='`fa-${icon}`'></i>
            </span>
        </div>
        <svg width='16'
             height='6'
             viewBox='0 0 16 6'
             fill='none'
             xmlns='http://www.w3.org/2000/svg'
             class='dropdown-v2__triangle'
             :class='[{"dropdown-v2__triangle--active": menuOpened}, direction]'
             v-if='!accordion && transparent'
        >
            <path d='M8 0.5C6 0.5 0.5 6 0.5 6H15.5C15.5 6 10 0.5 8 0.5Z' fill='white' />
        </svg>
        <div
            class='dropdown-v2-menu'
            :class='[
                {
                    "dropdown-v2-menu__active": menuOpened,
                    "accordion": accordion,
                    "transparent": transparent,
                },
                direction,
                menuExtraClasses
            ]'
        >
            <slot></slot>
        </div>
    </div>
</template>

<style lang="postcss">
.dropdown-v2 {
    position: relative;
    user-select: none;
    -webkit-user-select: none; /* Chrome, Safari, Opera */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE 10+ */
}

.dropdown-v2__triangle {
    @apply absolute -bottom-3 w-6;
    margin-left: calc(40% - 8px);
    opacity: 0;
    pointer-events: none;
    transform: translateY(5px);
    transition: all ease-in-out .1s;
}

.dropdown-v2__triangle--active {
    opacity: 1;
    transform: translateY(0);
}

.dropdown-v2__triangle.right {
    margin-right: calc(40% - 8px);
}

.dropdown-v2__triangle.topcenter {
    margin: 0;
    left: 50%;
    top: -6px;
    transform: scaleY(-1) translate(-50%, -100%);
}

.dropdown-v2-button {
    @apply border border-solid border-grey-50 bg-grey-50 rounded px-5 text-grey-700 h-12 flex items-center whitespace-nowrap cursor-pointer;
    overflow: hidden;
}

.dropdown-v2-button-small {
    @apply px-3 h-9;
}

.dropdown-v2-button.whiteName {
    @apply text-white;
}

.dropdown-v2-button.semi-transparent {
    @apply text-white;
    background-color: rgb(255 255 255 / .1);
    border-color: rgb(255 255 255 / .0);
}

.dropdown-v2-button.transparent {
    background: transparent;
    border-color: transparent;
}

.dropdown-v2-button.transparent:hover {
    @apply bg-blue-300;
}

.dropdown-v2-button.transparent.menuOpened {
    @apply bg-blue-600;
}

.dropdown-v2-button.accordion {
    @apply justify-center;
}

.dropdown-v2-button.noMargins {
    margin: 0;
    padding: 0;
}

.dropdown-v2-menu {
    @apply top-12 z-50;
    max-height: 200px;
    overflow-y: scroll;
    transition: all ease-in-out .1s;
    opacity: 0;
    position: absolute;
    pointer-events: none;
    transform: translateY(-5px);
}

/* Hide scrollbar for Chrome, Safari and Opera */
.dropdown-v2-menu::-webkit-scrollbar {
    display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.dropdown-v2-menu {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
}

.dropdown-v2-menu__active {
    opacity: 1;
    height: auto;
    pointer-events: auto;
    transform: translateY(0);
}

.dropdown-v2-menu.right {
    @apply rounded px-5 py-3 shadow-lg absolute right-0 bg-white;
}

.dropdown-v2-menu.left {
    @apply rounded px-5 py-3 shadow-lg absolute left-0 bg-white;
}

.dropdown-v2-menu.topcenter {
    @apply rounded px-5 py-3 shadow-lg absolute top-0 bg-white;
    right: 50%;
    transform: translate(50%, -100%);
}

.dropdown-v2-menu.accordion {
    @apply bg-transparent shadow-none flex flex-col items-center;
    max-height: auto;
    overflow-y: visible;
    transition: none;
}

.accordion.dropdown-v2-menu__active {
    position: static;
}

.dropdown-v2-menu.transparent.accordion {
    @apply text-white;
}

.dropdown-v2-item {
    @apply py-2 cursor-pointer;
}

.separator {
    @apply ml-2 text-grey-300;
}

.separator.semi-transparent {
    color: rgb(255 255 255 / .3);
}

.dropdown-v2-icon {
    @apply ml-3 text-xs opacity-70;
    transition: all ease-in-out .2s;
}

.dropdown-v2-icon--upper {
    margin-top: -2px;
}

.dropdown-v2-icon__active {
    transform: rotate(180deg);
}
</style>
