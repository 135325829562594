import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import * as Sentry from '@sentry/vue';
import {Integrations} from '@sentry/tracing';
import Validator from './validate.js';
import App from './App.vue';
import Api from './api';
import router from './router.js';
import utils from './utils.js';
import store from './store';
import i18n from './i18n.js';
import notify from './notify.js';
import loader from './loader.js';
import filters from './filters.js';
import bus from './bus.js';
import './analytics.js';
import VueTruncate from 'vue-truncate-filter';
import apollo from '@/apollo';
import PrettyCheckbox from 'pretty-checkbox-vue';
import 'pretty-checkbox/dist/pretty-checkbox.min.css';
import {Auth} from '@aws-amplify/auth';

apollo.init();

Sentry.init({
    Vue: Vue,
    dsn: 'https://e2b11324902f4c6e8c1416a3858d4bfa@o48503.ingest.sentry.io/103887',
    integrations: [new Integrations.BrowserTracing()],
    traceSampleRate: 1.0,
    logErrors: true,
});

Vue.use(VueTruncate);
Vue.use(PrettyCheckbox);

console.info('Loading MyCodaBox...');

if (window.location.href.indexOf('/verify') >= 0 ||
    window.location.href.indexOf('/refuse-verification') >= 0) {
    utils.nukeAuthentications();
}

var time = Date.now();

store.dispatch('init')
    .catch((err) => {
        console.warn('Uncaught Error during init', err);
    })
    .then(async () => {
        // Init Cognito
        Auth.configure({
            region: store.state.env.cognitoRegion,
            userPoolId: store.state.env.cognitoUserPoolId,
            userPoolWebClientId: store.state.env.cognitoClientId,
            mandatorySignIn: true,
        });
        //  Init Vue app
        const vue = new Vue({
            el: '#app',
            router: router,
            store: store,
            i18n: i18n,
            apolloProvider: apollo.getProvider(),
            render: h => h(App),
        });

        console.info('... Loaded in ' + (Date.now() - time) + 'msec');

        return vue;
    });

window.Vue = Vue;
window.utils = utils;
window.Api = Api;
window.store = store;
window.router = router;
window.i18n = i18n;
window.notify = notify;
window.loader = loader;
window.filters = filters;
window.bus = bus;
window.Validator = Validator;
