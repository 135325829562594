<script>
    import router from '@/router.js';
    import Loader from '@/loader.js';
    import validate from '@/validate.js';
    import notify from '@/notify.js';
    import _ from 'lodash';
    import i18n from '@/i18n.js';

    import ClientAddCoda from '@/components/ClientAddCoda.vue';
    import ClientAddSoda from '@/components/ClientAddSoda.vue';
    import RequiredNotice from '@/components/RequiredNotice.vue';
    import Warning from './components/Warning.vue';
    import Modal from '@/components/Modal.vue';
    import FormField from '@/components/FormField';
    import EnterpriseNumInput from '@/components/EnterpriseNumInput';
    import ContentBox from '@/components/ContentBox';
    import CustomTitle from '@/components/Title';
    import Translated from '@/components/Translated';
    import 'vue2-datepicker/index.css';
    import DatePicker from '@/components/DatePicker.vue';
    import { gql } from '@apollo/client/core';

    const CLIENT_FIELDS_REMAPPING = {
        'legal_entity.name': 'legal_entity_name',
        'legal_entity.enterprise_num': 'legal_entity_enterprise_num',
        'legal_entity.representative_name': 'legal_entity_representative_name',
        'legal_entity.representative_function': 'legal_entity_representative_function',
        'legal_entity.address': 'legal_entity_address',
        'legal_entity.address2': 'legal_entity_address2',
        'legal_entity.zip': 'legal_entity_zip',
        'legal_entity.city': 'legal_entity_city',
    };

    export default {
        name: 'fidu-new-client',
        props: {
            currentFiduciary: {
                type: Object,
            },
        },
        components: {
            FormField,
            EnterpriseNumInput,
            RequiredNotice,
            Warning,
            Modal,
            ClientAddCoda,
            ClientAddSoda,
            ContentBox,
            CustomTitle,
            Translated,
            DatePicker,
        },
        data () {
            return {
                ID: 0,
                clientData: {},
                platformTransferData: {},
                settings: [],
                transferablePopup: false,
                transferPayload: null,
                useContactEmailForPI: true,
                canEditPiEmail: false,
                saving: false,
                clientTransferData: {
                    bankAccounts: [],
                    socialOffices: [],
                    orderVoila: false,
                    voilaDeliveryEmail: null,
                    codaDeliveryStartDate: null,
                },
                specialErrors: {
                    uniqueClient: false,
                    uniqueBankAccount: false,
                    uniqueClientPlatform: false,
                    uniqueBankAccountPlatform: false,
                    uniqueArchivedClientPlatform: false,
                },
                specialErrorsData: {
                    uniqueArchivedClientPlatform: {},
                },
            };
        },
        computed: {
            isFiduExactOnline () {
                return this.currentFiduciary.isExactOnline;
            },
        },
        beforeMount () {
            this.initNewClient();
        },
        methods: {
            resetSpecialErrors () {
                this.specialErrors.uniqueClient = false;
                this.specialErrors.uniqueClientPlatform = false;
                this.specialErrors.uniqueBankAccount = false;
                this.specialErrors.uniqueBankAccountPlatform = false;
            },

            createClientData () {
                return {
                    address: '',
                    address2: '',
                    city: '',
                    clientCode: '',
                    contactEmail: '',
                    exactEmail: null,
                    enterpriseName: '',
                    enterpriseNumber: '',
                    fiduciaryId: this.currentFiduciary.id,
                    hasBelgianVatNumber: true,
                    language: '',
                    representativeFunction: '',
                    representativeName: '',
                    sendCodaAndSodaByMail: false,
                };
            },

            // init a new client data fields to collect inputs from new client form
            initNewClient () {
                this.clientData = this.createClientData();
            },

            clientTransferPayloadPrep () {
                _.assignIn(this.clientTransferData, {
                    targetFiduciaryId: this.currentFiduciary.id,
                    enterpriseName: this.clientData.enterpriseName,
                    enterpriseNumber: this.clientData.enterpriseNumber,
                    hasBelgianVatNumber: this.clientData.hasBelgianVatNumber,
                    representativeName: this.clientData.representativeName,
                    representativeFunction: this.clientData.representativeFunction,
                    address: this.clientData.address,
                    address2: this.clientData.address2,
                    zip: this.clientData.zip,
                    city: this.clientData.city,
                    clientCode: this.clientData.clientCode,
                    language: this.clientData.language,
                    contactName: this.clientData.representativeName,
                    contactEmail: this.clientData.contactEmail,
                    exactEmail: this.clientData.exactEmail,
                    sendCodaAndSodaByMail: this.clientData.sendCodaAndSodaByMail,
                });
            },

            async newClient () {
                const valid = await this.$refs.clientForm.validate();

                if (!valid) { return; }

                Loader.start();
                this.saving = true;
                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation NewClient($input: NewClientInput!) {
                            newClient(input: $input) {
                                data {
                                    id
                                    address
                                    address2
                                    zip
                                    city
                                    clientCode
                                    contactEmail
                                    exactEmail,
                                    enterpriseName
                                    enterpriseNumber
                                    fiduciaryId
                                    language
                                    representativeFunction
                                    representativeName
                                    sendCodaAndSodaByMail
                                }
                                errors {
                                    code,
                                    detail,
                                    source {
                                        pointer
                                    }
                                }
                            }
                        }`,
                        variables: {
                            input: this.clientData,
                        },
                    });
                    if (data.newClient.errors) {
                        this.saving = false;
                        if (data.newClient.errors.some(item => {
                            return (
                                item.source.pointer === '/data/enterpriseNumber' &&
                                item.detail === 'Conflict with client of another accountant.'
                            );
                        })) {
                            this.transferablePopup = true;

                            this.clientTransferPayloadPrep();

                            this.saving = false;
                            Loader.stop();

                            return;
                        }
                        validate.reportGQLFieldErrors(data.newClient.errors, this.$refs.clientForm, {
                            'Client code already used for this fiduciary.': 'err-client-code-not-unique',
                            'Already in use by a client of another fiduciary.': 'err-exact-email-not-unique',
                        }, CLIENT_FIELDS_REMAPPING);
                        Loader.stop();

                        if (data.newClient.errors) {
                            const err = data.newClient.errors;

                            const enterpriseNumberPointer = '/data/enterpriseNumber';

                            let platformTransferArchivedError = err.find(el => el.source.pointer === enterpriseNumberPointer && el.code === 'platformTransferArchived');
                            if (platformTransferArchivedError) {
                                /* Get error detail to extract the platform name and replace the detail message by a generic message
                                    because system will display the detail message in bottom of the form input
                                 */
                                const platformName = platformTransferArchivedError.detail.replace('This client already exists and is archived under the ', '').replace(' platform.', '');
                                platformTransferArchivedError.detail = i18n.t('err-unique-constraint');

                                this.specialErrors.uniqueArchivedClientPlatform = true;
                                this.specialErrorsData.uniqueArchivedClientPlatform = {
                                    'platformName': platformName,
                                };
                            } else if (err.some(el => el.source.pointer === enterpriseNumberPointer && el.code === 'unique')) {
                                this.specialErrors.uniqueClient = true;
                            }
                        }
                    } else {
                        Loader.stop();
                        notify.success(this.$t('suc-client-created'));
                        router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/client/${data.newClient.data.id}`);
                    }
                } catch (error) {
                    notify.error(this.$t('err-unknown'));
                    this.saving = false;
                    Loader.stop();
                }
            },

            async clientTransfer () {
                const valid = await this.$refs.clientTransferForm.validate();
                const codaValid = await this.$refs.coda.validateBankAccounts();
                const sodaValid = await this.$refs.soda.validateWelfareOffices();

                if (!valid || !codaValid || !sodaValid) { return; }

                // reformat bank accounts and social offices but keep original format for from fields
                let createClientTransferInput = Object.assign({}, this.clientTransferData);

                createClientTransferInput.bankAccounts = this.clientTransferData.bankAccounts.map(ba => { return ba.iban; });
                createClientTransferInput.socialOffices = this.clientTransferData.socialOffices.map(so => { return so.social_welfare_id; });

                Loader.start();
                this.saving = true;

                try {
                    const createMutation = await this.$apollo.mutate({
                        mutation: gql`mutation ($input: ClientTransferRequestCreateInput!) {
                            createClientTransferRequest(input: $input) {
                                errors { code, detail, source { pointer } }
                            }
                        }`,
                        variables: {
                            input: createClientTransferInput,
                        },
                    });

                    const createResponse = createMutation.data.createClientTransferRequest;

                    if (createResponse.errors) {
                        const allErrors = createResponse.errors;

                        // No errors on fields of the form expected: frontend validation is enough

                        // Global error: error with pointer to "/data"
                        const globalError = allErrors.find(error => {
                            return (
                                error.source && error.source.pointer && error.source.pointer === '/data'
                            );
                        });

                        if (globalError) {
                            if (globalError.code === 'alreadyExists' || globalError.code === 'alreadyExistsForFiduciary') {
                                // there is "alreadyExists" errors; display specific message
                                notify.error(this.$t('err-client-transfer-already-exists'));
                            } else {
                                // unexpected global error
                                notify.error(this.$t('err-unknown'));
                            }
                        } else {
                            // unexpected error
                            notify.error(this.$t('err-unknown'));
                        }

                        Loader.stop();
                        this.saving = false;
                    } else {
                        Loader.stop();
                        this.saving = false;
                        notify.success(this.$t('suc-client-transferred'));
                        router.push(`/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/clients/transfers/`);
                    }
                } catch (error) {
                    Loader.stop();
                    this.saving = false;
                    notify.error(this.$t('err-unknown'));
                }
            },
            nonAvailableDate (date) {
                const today = new Date();
                const twoYearsBefore = new Date();
                twoYearsBefore.setFullYear(today.getFullYear() - 2);
                return date >= today || date < twoYearsBefore;
            },
        },
    };
</script>
<template>
    <section>
        <div v-if='saving' class='cb-form-overlay'></div>

        <Modal :show='transferablePopup' large>
            <div class='modal-header alert-ok'>
                <h4>{{ $t('ttl-client-transfer-request-popup') }}</h4>
            </div>
            <ValidationObserver ref='clientTransferForm'
                                tag='div'
                                class='modal-body p-8'
                                id='clientTransferForm'
            >
                <p>
                    {{ $t('p-client-transfer-request-popup') }}
                </p>
                <div class='cb-fidu-title'>
                    <h2 class='mt-8 mb-3'>
                        {{ $t('ttl-add-coda') }}
                    </h2>
                </div>

                <ClientAddCoda
                    ref='coda'
                    v-model='clientTransferData.bankAccounts'
                    :min-input='1'
                />
                <DatePicker
                    v-model='clientTransferData.codaDeliveryStartDate'
                    :name='$t("lbl-resend-coda-transfer-title")'
                    :placeholder='$t("lbl-resend-coda-transfer-date-placeholder")'
                    :disabled-date='nonAvailableDate'
                    edit
                    class='mt-6'
                >
                    <template #info>
                        {{ $t('lbl-resend-coda-transfer-info') }}
                    </template>
                </DatePicker>

                <div class='cb-fidu-title'>
                    <div class='cb-fidu-actions'></div>
                    <h2 class='mt-8 mb-6'>
                        {{ $t('ttl-add-soda') }}
                    </h2>
                </div>

                <ClientAddSoda
                    ref='soda'
                    :needsone='false'
                    v-model='clientTransferData.socialOffices'
                />
            </ValidationObserver>
            <div class='modal-footer'>
                <button type='button' class='btn btn-default' @click.prevent='transferablePopup = false'>
                    {{ $t('btn-client-transfer-request-close') }}
                </button>
                <button type='button'
                        class='btn btn-primary'
                        @click.prevent='clientTransfer'
                >
                    {{ $t('btn-client-transfer-request-confirms') }}
                </button>
            </div>
        </Modal>
        <div class='cb-fidu-title'>
            <CustomTitle class='mt-12 mb-6'>
                {{ $t('nav-fidu-client-new') }}
            </CustomTitle>
        </div>

        <content-box :title='$t("h-client-info")'>
            <template #actions>
                <div class='form-inline text-center'>
                    <button class='btn  btn-alt' style='margin-right:10px' @click.prevent='initNewClient()'>
                        <i class='fa fa-undo'></i>
                        <span>{{ $t('btn-reset') }}</span>
                    </button>
                    <button
                        class='btn  btn-primary'
                        @click.prevent='newClient()'
                        :disabled='saving'
                        id='save'
                    >
                        <i class='fa fa-save'></i>
                        <span>{{ $t('btn-save-client') }}</span>
                    </button>
                </div>
            </template>
            <ValidationObserver ref='clientForm'
                                tag='div'
                                id='newClientForm'
            >
                <div class='row mb10'>
                    <div class='col-md-4'>
                        <FormField
                            v-model='clientData.clientCode'
                            vid='clientCode'
                            :name='$t("lbl-client-code")'
                            :placeholder='$t("lbl-client-code")'
                            edit
                            required
                            :max='50'
                            id='clientCode'
                        />
                        <FormField
                            v-model='clientData.contactEmail'
                            vid='contactEmail'
                            :name='$t("lbl-client-email")'
                            type='email'
                            :placeholder='$t("lbl-email")'
                            edit
                            required
                            :max='254'
                            id='contactEmail'
                        />

                        <FormField
                            v-model='clientData.language'
                            vid='language'
                            type='select'
                            :name='$t("lbl-language")'
                            :placeholder='$t("lbl-language-select")'
                            edit
                            required
                            id='language'
                        >
                            <option value='nl'>
                                {{ $t('lbl-dutch') }}
                            </option>
                            <option value='fr'>
                                {{ $t('lbl-french') }}
                            </option>
                            <option value='en'>
                                {{ $t('lbl-english') }}
                            </option>
                        </FormField>

                        <FormField
                            v-if='isFiduExactOnline'
                            v-model='clientData.exactEmail'
                            vid='exactEmail'
                            type='email'
                            :name='$t("lbl-exact-online")'
                            :placeholder='$t("lbl-email")'
                            :edit='true'
                            nullable
                            :max='254'
                            id='exactEmail'
                        />
                    </div>
                    <div class='col-md-4'>
                        <FormField
                            v-model='clientData.enterpriseName'
                            vid='enterpriseName'
                            :name='$t("lbl-enterprise-name")'
                            :placeholder='$t("lbl-enterprise-name")'
                            required
                            edit
                            :max='160'
                            id='enterpriseName'
                        />
                        <EnterpriseNumInput
                            v-model='clientData.enterpriseNumber'
                            vid='enterpriseNumber'
                            field='enterpriseNumber'
                            edit
                            required
                            id='enterpriseNumber'
                        />
                        <FormField
                            v-model='clientData.hasBelgianVatNumber'
                            vid='hasBelgianVatNumber'
                            field='hasBelgianVatNumber'
                            :name='$t("lbl-has-belgian-vat-number")'
                            type='boolean'
                            edit
                            required
                            id='hasBelgianVatNumber'
                        />
                        <FormField
                            v-model='clientData.representativeName'
                            vid='representativeName'
                            :name='$t("lbl-representative-name")'
                            :placeholder='$t("lbl-representative-name")'
                            :max='100'
                            required
                            edit
                            id='representativeName'
                            type='composedName'
                            clean
                            :info='$t("p-tooltip-representative-name")'
                        />
                        <FormField
                            v-model='clientData.representativeFunction'
                            vid='representativeFunction'
                            :name='$t("lbl-representative-function")'
                            :placeholder='$t("lbl-representative-function")'
                            required
                            edit
                            :max='128'
                            id='representativeFunction'
                        />
                    </div>
                    <div class='col-md-4'>
                        <FormField
                            v-model='clientData.address'
                            vid='address'
                            :name='$t("lbl-address")'
                            :placeholder='$t("lbl-address-line-1")'
                            required
                            edit
                            :max='100'
                            id='address'
                        />
                        <FormField
                            v-model='clientData.address2'
                            vid='address2'
                            :placeholder='$t("lbl-address-line-2")'
                            edit
                            :max='100'
                            id='address2'
                        />
                        <FormField
                            v-model='clientData.zip'
                            vid='zip'
                            :name='$t("lbl-zip")'
                            :placeholder='$t("lbl-zip")'
                            required
                            edit
                            :max='20'
                            id='zip'
                        />
                        <FormField
                            v-model='clientData.city'
                            vid='city'
                            :name='$t("lbl-city")'
                            :placeholder='$t("lbl-city")'
                            required
                            edit
                            :max='50'
                            id='city'
                        />
                    </div>
                </div>

                <div class='row box error mb-2' v-if='specialErrors.uniqueClient'>
                    <div class='col-md-12'>
                        <p class='m-0'>
                            <Translated>
                                <template #en>
                                    This client is already registered under this environment or under another environment.<br>
                                    If the client is registered under another environment, you can request an environment transfer. (For more information, visit our <a href='https://faq.codabox.com/en/support/solutions/articles/75000027132-request-a-platform-transfer' target='_blank'>CodaBox FAQ</a>)
                                </template>
                                <template #nl>
                                    Deze klant is al geregistreerd onder deze omgeving of onder een andere omgeving.<br>
                                    Als de klant geregistreerd staat onder een andere omgeving, kun je een overdracht van omgeving aanvragen. (Voor meer informatie, bezoek onze <a href='https://faq.codabox.com/nl/support/solutions/articles/75000027132-klant-overbrengen-naar-een-ander-platform' target='_blank'>CodaBox FAQ</a>)
                                </template>
                                <template #fr>
                                    Ce client est déjà inscrit sous cet environnement ou sous un autre environnement.<br>
                                    Si le client est enregistré sous un autre environnement, vous pouvez demander un transfert d'environnement. (Pour plus d’informations, visitez notre <a href='https://faq.codabox.com/fr/support/solutions/articles/75000027132-transf%C3%A9rer-un-client-vers-une-autre-plateforme' target='_blank'>FAQ CodaBox</a>)
                                </template>
                            </Translated>
                        </p>
                    </div>
                </div>

                <div class='row box error mb-2' v-if='specialErrors.uniqueArchivedClientPlatform'>
                    <div class='col-md-12'>
                        <p class='m-0'>
                            <Translated>
                                <template #en>
                                    This client already exists and is archived under the "{{ specialErrorsData.uniqueArchivedClientPlatform.platformName }}" environment.<br>
                                    To proceed you must add this client again under that environment as a "New Client" and request an environment transfer afterwards.<br>
                                    For more information, visit our <a href='https://faq.codabox.com/en/support/solutions/articles/75000086599-archiving-clients-via-mycodabox-questions-answers/#transfert' target='_blank'>CodaBox FAQ</a>.
                                </template>
                                <template #nl>
                                    Deze klant bestaat al en is gearchiveerd onder de "{{ specialErrorsData.uniqueArchivedClientPlatform.platformName }}" omgeving.<br>
                                    Om verder te gaan moet je deze klant opnieuw toevoegen onder die omgeving als een "Nieuwe Klant" en daarna een transfer van omgeving aanvragen.<br>
                                    Voor meer informatie, bezoek onze <a href='https://faq.codabox.com/nl/support/solutions/articles/75000086599-klanten-archiveren-via-mycodabox-vragen-antwoorden/#transfert' target='_blank'>CodaBox FAQ</a>.
                                </template>
                                <template #fr>
                                    Ce client existe déjà et est archivé sous l'environnement "{{ specialErrorsData.uniqueArchivedClientPlatform.platformName }}".<br>
                                    Pour poursuivre, vous devez ajouter à nouveau ce client sous cet environnement en tant que "Nouveau Client" et ensuite demander un transfert d'environnement.<br>
                                    Pour plus d'informations, veuillez consulter notre <a href='https://faq.codabox.com/fr/support/solutions/articles/75000086599-archivage-de-clients-via-mycodabox-questions-r%C3%A9ponses/#transfert' target='_blank'>FAQ CodaBox</a>.
                                </template>
                            </Translated>
                        </p>
                    </div>
                </div>

                <Warning v-if='clientData.exactEmail && !isFiduExactOnline'>
                    <template #header>
                        {{ $t('h-exact-email-warning') }}
                    </template>
                    {{ $t('p-exact-email-warning') }}
                </Warning>

                <RequiredNotice />
            </ValidationObserver>

            <p>
                <i class='fa fa-info-circle text-blue-500 mr-1'></i> {{ $t('p-new-client-info') }}
            </p>
        </content-box>
    </section>
</template>

<style lang='postcss' scoped>
    .client-subppage {
        min-height: calc(100vh - 450px);
    }

    .client-subppage-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 20px 0 20px;

        h1, h2, h3, h4, h5, h6 {
            margin: 0;
        }
    }

    .client-subppage-header .btn+.btn {
        @apply ml-3;
    }

    .client-page__settings {
        margin-top: 20px;
    }

    .client-subppage-subtitle {
        margin: 40px 0 20px 0;
    }

    .client-subppage-header__actions {
        @apply flex items-center ml-auto;
    }
</style>
