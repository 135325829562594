import Vue from 'vue';
import utils from './utils.js';
import i18n  from './i18n.js';
import notify from './notify.js';
import { ValidationProvider, ValidationObserver, localize, extend } from 'vee-validate';
import * as rules from 'vee-validate/dist/rules';
import fr from 'vee-validate/dist/locale/fr.json';
import nl from 'vee-validate/dist/locale/nl.json';
import en from 'vee-validate/dist/locale/en.json';
import _ from 'lodash';
import IBAN from 'iban';
import parsePhoneNumber from 'libphonenumber-js/max';
import {PASSWORD_RULES} from './constants/common';

// add the rules in all validate components
for (let [rule, validation] of Object.entries(rules)) {
    extend(rule, {
        ...validation,
    });
}

Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

// some customs
en.messages.length = 'This must contain {length} characters.';
en.messages.min = 'This must be at least {length} characters.';
en.messages.max = 'This cannot be greater than {length} characters.';
en.messages.min_value = 'Number must be bigger than {min}.';
en.messages.max_value = 'Number must be smaller than {max}.';
en.messages.integer = 'This must be a number (without decimals).';
en.messages.email = 'Invalid Email.';

fr.messages.length = 'Ce champ doit contenir {length} caractères.';
fr.messages.min = 'Ce champ doit être long d’au moins {length} caractères.';
fr.messages.max = 'Ce champ ne peut dépasser {length} caractères.';
fr.messages.min_value = 'Le nombre doit être plus grand que {min}.';
fr.messages.max_value = 'Le nombre doit être plus petit que {max}.';
fr.messages.integer = 'Ce champ doit contenir un nombre entier.';
fr.messages.email = 'Email invalide.';

nl.messages.length = 'Dit moet {length} karakters bevatten.';
nl.messages.min = 'Dit moet minimum {length} karakters bevatten.';
nl.messages.max = 'Dit kan niet groter zijn dan {length} karakters.';
nl.messages.min_value = 'Het nummer kan niet kleiner zijn dan {min}.';
nl.messages.max_value = 'Het nummer kan niet groter zijn dan {max}.';
nl.messages.integer = 'Dit moet een geheel getal zijn.';
nl.messages.email = 'E-mail ongeldig.';

localize({
    fr,
    nl,
    en,
});

extend('iban', iban => {
    if (!IBAN.isValid(iban)) {
        return i18n.t('err-invalid-iban');
    } else {
        return true;
    }
});

extend('enterpriseNumberFormat', enterpriseNumber => {
    const FORMAT = 'DDDDDDDDDD';
    if (enterpriseNumber.length && enterpriseNumber.length !== FORMAT.length) {
        return i18n.t('val-bad-format');
    } else {
        return true;
    }
});

extend('phone', phone => {
    const phoneNumber = parsePhoneNumber(phone, 'BE');
    if (phoneNumber && phone.charAt(0) === '+' && phoneNumber.country === 'BE' && phoneNumber.isValid()) {
        return true;
    } else {
        return i18n.t('val-bad-phone-format');
    }
});

extend('composedName', str => {
    return isComposedName(str) ? true : i18n.t('val-bad-composed-name-format');
});

function isComposedName (str) {
    const regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ'’-]+(?:\s[A-Za-zÀ-ÖØ-öø-ÿ'’-]+)+$/);
    return regex.test(str);
}

extend('name', str => {
    const regex = new RegExp(/^[A-Za-zÀ-ÖØ-öø-ÿ'’-\s]+$/);
    return regex.test(str) ? true : i18n.t('val-bad-name-format');
});

extend('ccsClientReference', number => {
    if (luhnChecksum(number) === 0 && (number.charAt(0) === '6' || number.charAt(0) === '7')) {
        return true;
    } else {
        return i18n.t('val-bad-ccs-client-reference-format');
    }
});

function checkPasswordRule (rgx, password) {
    const regex = new RegExp(rgx);
    return regex.test(password);
}

function isPasswordStrong (password) {
    for (const rule of PASSWORD_RULES) {
        if (password.length > 0 && !checkPasswordRule(rule.regex, password)) {
            return false;
        }
    }

    return true;
}

extend('password', pw => {
    return isPasswordStrong ? true : i18n.t('val-password-not-strong');
});

/* luhn_checksum
 * Implement the Luhn algorithm to calculate the Luhn check digit.
 * Return the check digit.
 */
function luhnChecksum (code) {
    var len = code.length;
    var parity = len % 2;
    var sum = 0;
    for (var i = len - 1; i >= 0; i--) {
        var d = parseInt(code.charAt(i));
        if (i % 2 === parity) { d *= 2; }
        if (d > 9) { d -= 9; }
        sum += d;
    }
    return sum % 10;
}

// Translations for API error messages
const translations = {
    'This field may not be blank.': 'err-required-constraint',
    'This field must be unique.': 'err-unique-constraint',
    'Invalid enterprise number.': 'err-invalid-enteprise-num',
    'Cannot sign or refuse purchase invoice mandate if one is already signed': 'err-cannot-sign-or-refuse-purchase-invoice-mandate',
    'Unable to login with provided credentials.': 'err-invalid-credentials',
    'Enter a valid email address.': 'err-email-not-valid',
    'This email is not known.': 'err-email-not-known',
    'This email is not known or already activated': 'err-email-not-known-or-activated',
    'The fields fiduciary_id, client_code must make a unique set.': 'err-client-code-not-unique',
    'Invalid user id or user doesn\'t exist.': 'err-invalid-user-id',
    'Invalid token for given user.': 'err-invalid-token-for-user',
    'Invalid password.': 'err-invalid-password',
    'Authentication credentials were not provided.': 'err-session-expired',
    'non_field_errors': 'lbl-non-field-errors',
    'email': 'lbl-email',
    'uid': 'lbl-uid',
    'current_password': 'lbl-current-password',
    'current_purchase_invoice_mandate_state': 'err-mandate-state',
    'enterprise_num': 'lbl-enterpriseNumber',
    'legal_entity.enterprise_num': 'lbl-enterpriseNumber',
    'Cannot sign or refuse purchase invoice mandate if the client\'s fiduciary has not agreed with purchase invoice mandate': 'err-fiduciary-agreement',
    'username': 'lbl-username',
    'This user does not have the mandate creation permission': 'err-mandate-creation-perm',
    'err-unknown': 'err-unknown',
    'err-server-fail': 'err-server-fail',
    'err-internet-problem': 'err-internet-problem',
    'Client already exists in the legal entity.': 'err-unique-constraint',
    'The legal entity of the fiduciary client has no enterprise number': 'err-missing-uen',
};

/*
 * Returns a translated error message,
 * using translations found in extraTranslations in priority.
 *
 *   translateMsg('Invalid user') -> "Utilisateur Invalide"
 *
 * Can also translate an array of error messages.
 *
 *   translateMsg(['Invalid user', 'err-unknown'])
 *   -> ['Utilisateur Invalide', 'Erreur Inconnue']
 */

function translateMsg (msg, extraTranslations) {
    if (msg instanceof Array) {
        var r = [];
        for (var m of msg) {
            r.push(translateMsg(m, extraTranslations));
        }
        return r;
    } else {
        extraTranslations = extraTranslations || {};
        if (extraTranslations[msg]) {
            return i18n.t(extraTranslations[msg]);
        } else if (translations[msg]) {
            return i18n.t(translations[msg]);
        } else {
            return msg;
        }
    }
}

/*
 * Catch field validation errors from Api error object.
 *
 * Api.rpc('POST','test/',{'foo':'bar'})
 *     .then((res) => { ... })
 *     .catch((err) => {
 *          return catchFieldErrors(err, this.errors, this.fields);
 *      });
 *
 * This necessitates access to Vue components this.errors & this.fields
 * which are available in all Vue components methods
 *
 * Only field errors corresponding to validated fields will be caught.
 *
 * If there are uncaught fields, or other errors, this returns a
 * failed promise with the uncaught errors.
 */

function catchFieldErrors (err, observer, extraTranslations, fieldMap) {
    if (!err || err.msg === '[vee-validate]: Validation Failed') {
        return Promise.resolve();
    } else if (err.error !== 'api' || err.status !== 400) {
        return Promise.reject(err);
    } else {
        fieldMap = fieldMap || {};
        var uncaught = {};
        if (Array.isArray(err.body.errors)) {
            let observerErrors = {};
            err.body.errors.forEach(error => {
                const field = error.source.pointer.replace('/data/', '');
                observerErrors[field] = translateMsg(error.detail, extraTranslations);
            });
            observer.setErrors(observerErrors);
        } else {
            var errors   = utils.flatten(err.body);
            for (var field in errors) {
                var _field = fieldMap[field] || field;
                if (observer.fields[_field]) {
                    let observerError = {};
                    if (errors[field] instanceof Array) {
                        for (var msg of errors[field]) {
                            observerError[_field] = translateMsg(msg, extraTranslations);
                        }
                    } else {
                        observerError[_field] = translateMsg(errors[field], extraTranslations);
                    }
                    observer.setErrors(observerError);
                } else {
                    uncaught[field] = errors[field];
                }
            }
        }

        if (utils.count(uncaught)) {
            return Promise.reject({
                error: err.error,
                status: err.status,
                body: uncaught,
            });
        } else {
            return Promise.resolve();
        }
    }
}

function reportGQLFieldErrors (errors, observer, extraTranslations) {
    /**
     * Notify validation errors from GraphQL related to a field.
     *
     * Errors format from backend, defined in Codabox guidelines:
     * {
     *   "errors": [
     *     {
     *       "code": "required",
     *       "title": "string",
     *       "detail": "string",
     *       "source": {
     *         "pointer": "/data"
     *       }
     *     }
     *   ]
     * }
     * where source/pointer is optional and is the field name if it's an error related to a field.
     *
     * Rule to detect error related to a field:
     *  - there is a source/pointer
     *  - AND the source/pointer is not "/data"
     */
    let observerErrors = {};
    errors.forEach(error => {
        if (error.source) {
            const field = error.source.pointer.replace('/data/', '').replace('/', '');
            observerErrors[field] = translateMsg(error.detail, extraTranslations);
        }
    });
    observer.setErrors(observerErrors);
}

function notifyGQLValidationErrors (errors, extraTranslations) {
    /**
     * Notify validation errors from GraphQL not related to a field.
     *
     * Errors format from backend, defined in Codabox guidelines:
     * {
     *   "errors": [
     *     {
     *       "code": "required",
     *       "title": "string",
     *       "detail": "string",
     *       "source": {
     *         "pointer": "/data"
     *       }
     *     }
     *   ]
     * }
     * where source/pointer is optional and is the field name if it's an error related to a field.
     *
     * Rule to detect error not related to a field:
     *  - there is no source
     *  - OR there is no source/pointer
     *  - OR the source/pointer is "/data"
     */
    let messages = [];

    errors.forEach(error => {
        if (!error.source || (error.source && !error.source.pointer) || (error.source && error.source.pointer && error.source.pointer === '/data')) {
            messages.push(translateMsg(error.code, extraTranslations));
        }
    });
    if (messages.length !== 0) {
        notify.error(messages.join(' \n'));
    }
}

function notifyGraphQLErrors (e, extraTranslations) {
    let code;
    // detect if errors is an array
    if (e.length) {
        e.forEach(error => {
            code = error.extensions.code;
            notify.error(translateMsg(code, extraTranslations));
        });
    } else {
        code = e.extensions.code;
        notify.error(translateMsg(code, extraTranslations));
    }
    if (!code) {
        notify.error(translateMsg('err-unknown'));
    }
}

function getBodyMessages (err, extraTranslations) {
    let msgs = [];

    if (err.body && err.body.errors) {
        // means it's the new guideline
        err.body.errors.forEach(error => {
            msgs.push(translateMsg(error.detail, extraTranslations));
        });
    } else {
        const errors = utils.flatten(err.body);
        for (var field in errors) {
            if (typeof errors[field] === 'string') {
                errors[field] = [errors[field]];
            }

            if (field === 'non_field_errors') {
                msgs.push(translateMsg(errors[field], extraTranslations).join(', '));
            } else {
                msgs.push(translateMsg(field, extraTranslations) +
                          ': ' +
                          translateMsg(errors[field], extraTranslations).join(', '));
            }
        }
    }

    return msgs.join(' \n');
}

/* Display notifications for all 400 Api errors in rpc error object */
function notifyApiErrors (err, extraTranslations) {
    if (err.error !== 'api' || err.status !== 400) {
        return Promise.reject(err);
    } else {
        const msgs = getBodyMessages(err, extraTranslations);
        notify.error(msgs);
        return Promise.resolve();
    }
}

/* Display notifications for all 403 errors in rpc error object */
function notifyAuthErrors (err, extraTranslations) {
    if (err.error !== 'api' || !(err.status === 403 || err.status === 401)) {
        return Promise.reject(err);
    } else {
        var errors = utils.flatten(err.body);
        if (errors.detail) {
            notify.error(translateMsg(errors.detail, extraTranslations));
        } else {
            notify.error(i18n.t('err-session-expired'));
        }
        return Promise.resolve();
    }
}

/* Display notifications for all Server errors in rpc error object */
function notifyServerErrors (err, extraTranslations) {
    if (err.error !== 'server') {
        return Promise.reject(err);
    } else {
        const msgs = getBodyMessages(err, extraTranslations);
        if (msgs.length > 0) {
            notify.error(msgs);
        } else {
            notify.error(i18n.t('err-server-fail'));
        }
        return Promise.resolve();
    }
}

/* Display notifications for all Network errors in rpc error object */
function notifyNetworkErrors (err, extraTranslations) {
    if (err.error !== 'network') {
        return Promise.reject(err);
    } else {
        notify.error(i18n.t('err-server-fail'));
        return Promise.resolve();
    }
}

/* Display notifications for all errors in rpc error object */
function notifyErrors (err, extraTranslations) {
    return notifyApiErrors(err, extraTranslations)
        .catch((err) => {
            return notifyAuthErrors(err, extraTranslations);
        }).catch((err) => {
            return notifyServerErrors(err, extraTranslations);
        }).catch((err) => {
            return notifyNetworkErrors(err, extraTranslations);
        });
}

/* Ignore some errors */
function ignoreFieldsErrors (err, errorsToIgnore) {
    if (!err || err.msg === '[vee-validate]: Validation Failed') {
        return Promise.resolve();
    } else if (err.error !== 'api' || err.status !== 400) {
        return Promise.reject(err);
    } else {
        errorsToIgnore = errorsToIgnore || [];
        const errors = err.body;
        errorsToIgnore.forEach(errorToIgnore => {
            if (_.has(errors, errorToIgnore)) {
                _.unset(errors, errorToIgnore);
            }
        });
        if (utils.count(errors)) {
            return Promise.reject({
                error: err.error,
                status: err.status,
                body: errors,
            });
        } else {
            return Promise.resolve();
        }
    }
}

/* -- I18N integration -- */

const _LOAD = i18n.loadSavedLocale;
i18n.loadSavedLocale = function () {
    var locale = _LOAD();
    localize(i18n.simpleLocale(locale));
    return locale;
};

const _SET = i18n.setLocale;
i18n.setLocale = function (locale) {
    if (locale) {
        localize(i18n.simpleLocale(locale));
    }
    return _SET(locale);
};

i18n.loadSavedLocale();

function Validator () {};

export default {
    catchFieldErrors,
    reportGQLFieldErrors,
    notifyGraphQLErrors,
    notifyGQLValidationErrors,
    notifyApiErrors,
    notifyAuthErrors,
    notifyServerErrors,
    notifyNetworkErrors,
    ignoreFieldsErrors,
    notifyErrors,
    translateMsg,
    Validator,
    isComposedName,
    isPasswordStrong,
    checkPasswordRule,
};
