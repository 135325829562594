<template>
    <Popup :show='show' :close='close' :fixed-height='false' :small='$store.state.gui === "widescreen"'>
        <template slot='title'>
            <div v-if='step===0'>
                {{ $t('ttl-needs-migration') }}
            </div>
            <div v-else-if='step===1'>
                {{ $t('ttl-migration-started') }}
            </div>
            <div v-else-if='step===2'>
                {{ $t('ttl-already-migrated') }}
            </div>
        </template>
        <div v-if='step===0'>
            <Translated>
                <template #en>
                    <p>
                        We have updated our login system to make MyCodaBox easier to use and even more secure. This does require you to migrate your current account.
                    </p>
                    <p>
                        No worries! There’s no impact on your MyCodaBox data and it only takes a few minutes. After the migration, you will be able to work in MyCodaBox as before.
                    </p>
                    <p class='font-bold text-blue-400'>
                        Start the migration by entering your own email address. This will become your new login to MyCodaBox.
                    </p>
                    <p class='italic' v-if='organization'>
                        Do you share a MyCodaBox account with your colleagues? We strongly advise you to migrate the account to your own email address. This is more secure, and you can easily invite your colleagues in MyCodaBox afterwards to create their own account.
                    </p>
                </template>
                <template #nl>
                    <p>
                        We hebben ons inlogsysteem vernieuwd om MyCodaBox gebruiksvriendelijker en nog veiliger te maken. Hiervoor moet je je huidige account migreren.
                    </p>
                    <p>
                        Geen zorgen! Er is geen impact op je MyCodaBox gegevens en het duurt maar een paar minuten. Na de migratie kan je verder werken in MyCodaBox zoals je gewoon bent.
                    </p>
                    <p class='font-bold text-blue-400'>
                        Start de migratie door je eigen e-mailadres in te voeren. Dit wordt je nieuwe login voor MyCodaBox.
                    </p>
                    <p class='italic' v-if='organization'>
                        Deel je een MyCodaBox account met je collega's? Wij raden je sterk aan om het account te migreren naar jouw eigen e-mailadres. Dit is veel veiliger, en je kunt je collega's nadien rechtstreeks uitnodigen in MyCodaBox.
                    </p>
                </template>
                <template #fr>
                    <p>
                        Nous avons mis à jour notre système de connexion pour rendre MyCodaBox plus facile à utiliser et encore plus sûr. Pour ce faire, vous devez migrer votre compte actuel.
                    </p>
                    <p>
                        Ne vous inquiétez pas ! Il n'y a aucun impact sur vos données MyCodaBox et cela ne prend que quelques minutes. Après la migration, vous pourrez travailler dans MyCodaBox comme avant.
                    </p>
                    <p class='font-bold text-blue-400'>
                        Commencez la migration en indiquant votre adresse e-mail. Celle-ci deviendra votre nouveau login.
                    </p>
                    <p class='italic' v-if='organization'>
                        Vous partagez un compte MyCodaBox avec vos collègues ? Nous vous conseillons vivement de migrer le compte vers votre adresse e-mail personnelle, qu'elle soit professionnelle ou non, pour des raisons de sécurité. Vous pourrez ensuite facilement inviter vos collègues directement dans MyCodaBox.
                    </p>
                </template>
            </Translated>
            <ValidationObserver ref='loginForm' tag='div' @keyup.enter='onStartMigration'>
                <FriendlyInput
                    autocomplete='email'
                    label='lbl-migration-email'
                    v-model='email'
                    mode='eager'
                    field='email'
                    required
                    :placeholder='$t("placeholder-email")'
                    rules='email'
                    text-size='xl'
                    ref='emailInput'
                />
                <div class='mt-6'>
                    <FriendlyButton label='btn-start-migration'
                                    no-margin
                                    square
                                    small
                                    :action='onStartMigration'
                                    :disabled='!utils.validateEmail(email) || email.length === 0'
                    />
                </div>
            </ValidationObserver>
        </div>
        <div v-else-if='step===1'>
            <Translated>
                <template #en>
                    <p>
                        We sent an email to <span class='font-bold'>{{ email }}</span> with instructions to complete the migration. Make sure to check your spam folder in case the email ended up there.
                    </p>
                    <p>
                        As part of the migration process you will be asked to sign up again for a MyCodaBox account.
                    </p>
                    <template v-if='organization'>
                        <p class='italic'>
                            You did not receive any email? Or need more help?
                        </p>
                        <p>
                            Have a look at our <a :href='$t("a-href-faq-migration")' target='_blank'>FAQ article</a> or contact us via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </p>
                    </template>
                    <template v-else-if='reseller'>
                        <p class='italic'>
                            You did not receive any email? Or need more help? Contact us via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </p>
                    </template>
                </template>
                <template #nl>
                    <p>
                        We hebben een e-mail gestuurd naar <span class='font-bold'>{{ email }}</span> met instructies om de migratie te voltooien. Controleer je spamfolder voor het geval de e-mail daar terecht is gekomen.
                    </p>
                    <p>
                        Als onderdeel van de migratie zal je gevraagd worden om je opnieuw te registreren voor een MyCodaBox account.
                    </p>
                    <template v-if='organization'>
                        <p class='italic'>
                            Heb je geen e-mail ontvangen? Of heb je meer hulp nodig?
                        </p>
                        <p>
                            Bekijk ons <a :href='$t("a-href-faq-migration")' target='_blank'>FAQ artikel</a> of neem contact met ons op via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </p>
                    </template>
                    <template v-else-if='reseller'>
                        <p class='italic'>
                            Heb je geen e-mail ontvangen? Of heb je meer hulp nodig? Neem contact met ons op via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </p>
                    </template>
                </template>
                <template #fr>
                    <p>
                        Nous avons envoyé un e-mail à <span class='font-bold'>{{ email }}</span> avec les instructions pour compléter la migration. Si vous n’avez pas reçu l'e-mail, veuillez vérifier vos courriers indésirables.
                    </p>
                    <p>
                        Dans le cadre de la migration, il vous sera demandé de vous réinscrire pour un compte MyCodaBox.
                    </p>
                    <template v-if='organization'>
                        <p class='italic'>
                            Vous n'avez pas reçu d'e-mail ? Ou vous avez besoin d'aide ?
                        </p>
                        <p>
                            Consultez notre <a :href='$t("a-href-faq-migration")' target='_blank'>article FAQ</a> ou contactez-nous via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>.
                        </p>
                    </template>
                    <template v-else-if='reseller'>
                        <p class='italic'>
                            Vous n'avez pas reçu d'e-mail ? Ou vous avez besoin d'aide ? Contactez-nous via <a href='mailto:helpdesk@codabox.com'>helpdesk@codabox.com</a>
                        </p>
                    </template>
                </template>
            </Translated>
            <FriendlyButton label='btn-close-window'
                            no-margin
                            square
                            small
                            :action='close'
            />
        </div>
        <div v-else-if='step===2'>
            <Translated>
                <template #nl>
                    <p v-if='organization'>
                        Er is al een MyCodaBox account met <span class='font-bold'>{{ email }}</span> gekoppeld aan jouw organisatie.
                    </p>
                    <p v-else-if='reseller'>
                        Er is al een MyCodaBox account met <span class='font-bold'>{{ email }}</span> gekoppeld aan jouw Reseller organisatie.
                    </p>
                    <p>
                        Je kan verder gaan naar MyCodaBox en inloggen met je e-mailadres!
                    </p>
                </template>
                <template #en>
                    <p v-if='organization'>
                        There’s already a MyCodaBox account with <span class='font-bold'>{{ email }}</span> linked to your organization.
                    </p>
                    <p v-else-if='reseller'>
                        There’s already a MyCodaBox account with <span class='font-bold'>{{ email }}</span> linked to your Reseller organization.
                    </p>
                    <p>
                        You can continue to MyCodaBox and log in with your email address!
                    </p>
                </template>
                <template #fr>
                    <p v-if='organization'>
                        Il existe déjà un compte MyCodaBox avec <span class='font-bold'>{{ email }}</span> lié à votre organisation.
                    </p>
                    <p v-else-if='reseller'>
                        Il existe déjà un compte MyCodaBox avec <span class='font-bold'>{{ email }}</span> lié à votre organisation Revendeur.
                    </p>
                    <p>
                        Si c'est le cas, vous pouvez utiliser cette adresse email pour vous connecter.
                    </p>
                </template>
            </Translated>
            <FriendlyButton label='btn-close-window'
                            no-margin
                            square
                            small
                            :action='close'
            />
        </div>
    </Popup>
</template>
<script>
import Popup from '@/clientcomponents/Popup.vue';
import Translated from '@/components/Translated';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import FriendlyInput from '@/clientcomponents/FriendlyInput.vue';
import utils from '@/utils';

export default {
    props: {
        show: {
            type: Boolean,
            required: true,
        },
        close: {
            type: Function,
            required: true,
        },
        callback: {
            type: Function,
            required: true,
        },
        coreData: Object,
    },
    components: {
        FriendlyInput,
        FriendlyButton,
        Popup,
        Translated,
    },
    data: function () {
        return {
            step: 0,
            email: '',
        };
    },
    computed: {
        utils () {
            return utils;
        },
        organization () {
            return typeof (this.coreData.organizationId) === 'string';
        },
        reseller () {
            return typeof (this.coreData.resellerId) === 'string';
        },
    },
    methods: {
        async onStartMigration () {
            if (!(await this.$refs.loginForm.validate())) return false;

            if (await this.callback(this.email)) {
                this.step = 1;
            } else {
                this.step = 2;
            }
        },
    },
};
</script>

<style scoped>
p {
    @apply text-xl pb-4;
}
</style>
