<template>
    <div class='container'>
        <Breadcrumb :more='{ organization: organization.enterpriseName }' class='mt-3' />
        <div class='min-h-screen'>
            <div class='flex flex-col justify-between min-h-screen'>
                <router-view :organization='organization' />
                <Footer />
            </div>
        </div>
    </div>
</template>

<script>
    import Footer from '@/components/FancyFooter.vue';
    import Breadcrumb from '@/components/Breadcrumb.vue';

    export default {
        name: 'org-administration',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        components: {
            Footer,
            Breadcrumb,
        },
    };
  </script>
