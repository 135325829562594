<script>
    import vt from 'vue-types';

    export default {
        name: 'clientlink',
        props: {
            client: vt.object.isRequired,
            document: vt.string,
        },
        computed: {
            url () {
                var url = `/organization/${this.$route.params.organizationId}/environment/${this.$route.params.environmentId}/client/${this.client.id || this.client.uuid}/`;
                if (this.document) {
                    url += '?doc=' + this.document;
                }
                return url;
            },
        },
    };
</script>
<template>
    <router-link :to='url'>
        {{ client.client_code }}
    </router-link>
</template>
