<template>
    <div>
        <div class='flex items-end mt-6 mb-6'>
            <div>
                <CustomTitle class='m-0'>
                    {{ $t('ttl-organization-users') }}
                </CustomTitle>
            </div>
            <div class='ml-auto flex'>
                <FriendlyButton
                    class='ml-2'
                    label='lbl-invite-organization-user'
                    extra-small
                    no-margin
                    square
                    id='invite-organization-user-button'
                    :action='openInviteUserPopup'
                    symbol='user-plus'
                />
            </div>
        </div>
        <div class='flex mt-6 mb-6'>
            <p class='text-gray-500'>
                {{ $t("ttl-organization-users-info-1") }} <span class='font-bold'>{{ organization.enterpriseName }}</span>.<br>
                {{ $t("ttl-organization-users-info-2") }}
            </p>
        </div>
        <ContentBox>
            <List :loading='loading'>
                <template #columns>
                    <ListColumn>{{ $t('col-organization-users-email') }}</ListColumn>
                    <ListColumn>{{ $t('col-organization-users-name') }}</ListColumn>
                    <ListColumn>{{ $t('col-organization-users-status') }}</ListColumn>
                    <ListColumn class='text-right'>
                        {{ $t('col-organization-users-actions') }}
                    </ListColumn>
                </template>
                <template #rows>
                    <ListRow v-for='(user, i) in users' :key='i'>
                        <ListItem>
                            <strong>{{ user.email }}</strong>
                        </ListItem>
                        <ListItem v-if='user.firstName && user.lastName'>
                            {{ user.firstName }} {{ user.lastName }}
                        </ListItem>
                        <ListItem v-else>
                            -
                        </ListItem>
                        <ListItem>
                            <StatePill :label='$t(`lbl-organization-users-status-${user.status}`)' :background-class='userStatusBackgroundClass(user.status)' inline />
                        </ListItem>
                        <ListItem>
                            <div class='flex justify-end'>
                                <FriendlyButton
                                    v-if='user.userId !== $store.state.user.userId && ["invitation-sent","invitation-expired"].includes(user.status)'
                                    symbol='repeat'
                                    extra-small
                                    no-margin
                                    square
                                    no-text
                                    secondary
                                    no-background
                                    fat-icon
                                    :action='() => openUserResendInvitationPopup(user)'
                                />
                                <FriendlyButton
                                    v-if='user.userId !== $store.state.user.userId'
                                    symbol='trash'
                                    extra-small
                                    no-margin
                                    square
                                    no-text
                                    danger
                                    no-background
                                    fat-icon
                                    :action='() => openUserRemovalPopup(user)'
                                />
                            </div>
                        </ListItem>
                    </ListRow>
                </template>
            </List>
        </ContentBox>

        <Popup :show='userInvitationPopup' :close='closeInviteUserPopup' small>
            <template slot='title'>
                {{ $t('ttl-popup-invite-organization-user') }} {{ organization.enterpriseName }}
            </template>

            <p class='text-grey-500 mb-2'>
                {{ $t('invite-user-text') }}
            </p>
            <p class='text-grey-500 mb-6'>
                <span class='font-bold text-orange-300'>{{ $t('invite-user-text-note-p1') }}</span> {{ $t('invite-user-text-note-p2') }}
            </p>

            <ValidationObserver ref='userInvitationForm'>
                <div>
                    <FormField
                        v-model='userInvitationEmail'
                        vid='email'
                        :name='$t("lbl-popup-invite-organization-user-email")'
                        type='email'
                        :placeholder='$t("lbl-email")'
                        edit
                        required
                        :max='254'
                    />
                </div>
            </ValidationObserver>
            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-cancel'
                    :action='closeInviteUserPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-invite-organization-user'
                    :action='submitUserInvitation'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <Popup :show='userRemovalPopup' :close='closeUserRemovalPopup'>
            <template slot='title'>
                {{ $t('ttl-popup-remove-organization-user') }}
            </template>

            <div>
                <p>
                    <Translated>
                        <template #en>
                            <b>{{ userRemovedDisplayName }}</b> will not be able to access <b>{{ organization.enterpriseName }}</b> via MyCodaBox.
                        </template>
                        <template #fr>
                            <b>{{ userRemovedDisplayName }}</b> ne pourra pas accéder à <b>{{ organization.enterpriseName }}</b> via MyCodaBox.
                        </template>
                        <template #nl>
                            <b>{{ userRemovedDisplayName }}</b> zal geen toegang hebben tot <b>{{ organization.enterpriseName }}</b> via MyCodaBox.
                        </template>
                    </Translated>
                </p>
            </div>
            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-popup-cancel-remove-organization-user'
                    :action='closeUserRemovalPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-remove-organization-user'
                    :action='submitUserRemovalPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>

        <Popup :show='userResendInvitationPopup' :close='closeUserResendInvitationPopup'>
            <template slot='title'>
                {{ $t('ttl-popup-remove-organization-user') }}
            </template>

            <div>
                <p>
                    <Translated>
                        <template #en>
                            An email will be sent again to <b>{{ userResend.email }}</b> to sign up for a MyCodaBox account or to accept the invitation.
                        </template>
                        <template #fr>
                            Un nouvel e-mail sera envoyé à l'adresse e-mail <b>{{ userResend.email }}</b> pour la création d'un compte MyCodaBox ou pour accepter l'invitation.
                        </template>
                        <template #nl>
                            Er zal opnieuw een e-mail verstuurd worden naar <b>{{ userResend.email }}</b> om zich te registreren voor een MyCodaBox account of om de uitnodiging te accepteren.
                        </template>
                    </Translated>
                </p>
            </div>
            <template slot='buttons'>
                <FriendlyButton
                    label='lbl-popup-cancel-remove-organization-user'
                    :action='closeUserResendInvitationPopup'
                    symbol='times'
                    small
                    square
                    secondary
                />
                <FriendlyButton
                    label='lbl-popup-resend-invitation'
                    :action='submitUserResendInvitationPopup'
                    small
                    symbol='check'
                    square
                />
            </template>
        </Popup>
    </div>
</template>

<script>
    import List from '@/components/List';
    import ListColumn from '@/components/ListColumn';
    import ListRow from '@/components/ListRow';
    import ListItem from '@/components/ListItem';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import ContentBox from '@/components/ContentBox';
    import Popup from '@/clientcomponents/Popup.vue';
    import FormField from '@/components/FormField';
    import notify from '@/notify.js';
    import CustomTitle from '@/components/Title';
    import { gql } from '@apollo/client/core';
    import StatePill from '@/components/StatePill.vue';
    import Translated from '@/components/Translated';
    import utils from '@/utils';
    import Loader from '@/loader.js';

    export default {
        name: 'organization-users-view',
        props: {
            organization: {
                type: Object,
                required: true,
            },
        },
        components: {
            Translated,
            List,
            ListColumn,
            ListRow,
            ListItem,
            FriendlyButton,
            ContentBox,
            Popup,
            FormField,
            CustomTitle,
            StatePill,
        },
        data () {
            return {
                loading: false,
                users: [],
                userInvitationPopup: false,
                userInvitationEmail: '',
                userRemovalPopup: false,
                userRemoved: null,
                userResendInvitationPopup: false,
                userResend: null,
            };
        },
        computed: {
            userRemovedDisplayName () {
                if (this.userRemoved) {
                    return this.userRemoved.firstName && this.userRemoved.lastName ? `${this.userRemoved.firstName} ${this.userRemoved.lastName}` : this.userRemoved.email;
                } else {
                    return '';
                }
            },
        },
        mounted () {
            this.fetchList();
        },
        methods: {
            async fetchList () {
                this.loading = true;
                this.users = await this.getUsers();
                this.loading = false;
            },
            async fetchListNoSpinner () {
                this.users = await this.getUsers();
            },
            async getUsers () {
                try {
                    const results = await this.$apollo.query({
                        query: gql`query OrganizationUsers($organizationId: String) {
                            organizationUsers(organizationId: $organizationId) {
                                userId
                                organizationUserId
                                role
                                email
                                firstName
                                lastName
                            },
                            invitedUsers(organizationId: $organizationId) {
                                id,
                                email,
                                expiresAt
                            }
                        }`,
                        variables: {
                            organizationId: this.$route.params.organizationId,
                        },
                    });

                    // merge the arrays, add state and sort A-Z based on email
                    const today = new Date();
                    return [
                        ...results.data.organizationUsers,
                        // rename ID to invitationId for the invitations list to avoid confusion:
                        ...results.data.invitedUsers.map((invitation) => { return {invitationId: invitation.id, email: invitation.email, expiresAt: invitation.expiresAt}; }),
                    ].map(user => {
                        let status;
                        if (user.expiresAt) {
                            const expiresAt = new Date(user.expiresAt);
                            status = expiresAt > today ? 'invitation-sent' : 'invitation-expired';
                        } else {
                            status = 'active';
                        }
                        return { ...user, status };
                    }).sort((a, b) => { return a.email.localeCompare(b.email); });
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                } finally {
                    this.loading = false;
                }
            },
            async submitUserInvitation () {
                const valid = await this.$refs.userInvitationForm.validate();
                if (!valid) { return; }
                try {
                    const invitationResult = await this.$apollo.query({
                        query: gql`mutation InviteOrganizationUser($organizationId: String, $email: String) {
                            inviteOrganizationUser(organizationId: $organizationId, email: $email) {
                                errors {
                                    code
                                    detail
                                }
                            }
                        }`,
                        variables: {
                            organizationId: this.organization.id,
                            email: this.userInvitationEmail,
                        },
                    });

                    const errors = invitationResult.data.inviteOrganizationUser.errors;
                    if (errors && errors.length > 0) {
                        notify.error(this.$t('err-add-orga-user'));
                    } else {
                        notify.success(this.$t('succ-add-orga-user', { organizationName: this.organization.enterpriseName }));
                    }
                } catch {
                    notify.error(this.$t('err-add-orga-user'));
                }

                await this.fetchList();

                this.closeInviteUserPopup();
            },
            openInviteUserPopup (email) {
                this.userInvitationEmail = email;
                this.userInvitationPopup = true;
            },
            closeInviteUserPopup () {
                this.userInvitationPopup = false;
            },
            async submitUserResendInvitationPopup () {
                const id = Loader.start();
                if (this.userResend && this.userResend.invitationId) {
                    try {
                        // delete existing invitation
                        const deleteResult = await this.$apollo.query({
                            query: gql`mutation DeleteUserInvitation($invitationId: String) {
                                deleteUserInvitation(invitationId: $invitationId) {
                                    errors {
                                        code
                                        detail
                                    }
                                }
                            }`,
                            variables: {
                                invitationId: this.userResend.invitationId,
                            },
                        });
                        const deleteErrors = deleteResult.data.deleteUserInvitation.errors;
                        if (deleteErrors && deleteErrors.length > 0) {
                            notify.error(this.$t('err-resend-invitation'));
                            return;
                        }
                        // recreate invitation
                        const invitationResult = await this.$apollo.query({
                            query: gql`mutation InviteOrganizationUser($organizationId: String, $email: String) {
                            inviteOrganizationUser(organizationId: $organizationId, email: $email) {
                                errors {
                                    code
                                    detail
                                }
                            }
                        }`,
                            variables: {
                                organizationId: this.organization.id,
                                email: this.userResend.email,
                            },
                        });
                        const errors = invitationResult.data.inviteOrganizationUser.errors;
                        if (errors && errors.length > 0) {
                            notify.error(this.$t('err-resend-invitation'));
                            return;
                        }
                        notify.success(this.$t('succ-resend-invitation'));
                    } catch (e) {
                        notify.error(this.$t('err-resend-invitation'));
                    }
                } else {
                    notify.error(this.$t('err-resend-invitation'));
                }
                this.closeUserResendInvitationPopup();
                await this.fetchListNoSpinner();
                Loader.stop(id);
            },
            async submitUserRemovalPopup () {
                // FIXME: Delete INVITATION via this popup should be temporary, only for MVP.
                // FIXME: It should be removed from this popup when the resend invitation will be implemented.

                // Depending of the ID set on user:
                //      organizationUserId -> delete organization user
                //      invitationId -> delete invitation
                if (this.userRemoved && this.userRemoved.organizationUserId) {
                    try {
                        const removeResult = await this.$apollo.query({
                            query: gql`mutation RemoveOrganizationUser($organizationUserId: String) {
                                removeOrganizationUser(organizationUserId: $organizationUserId) {
                                    errors {
                                        code
                                        detail
                                    }
                                }
                            }`,
                            variables: {
                                organizationUserId: this.userRemoved.organizationUserId,
                            },
                        });

                        const errors = removeResult.data.removeOrganizationUser.errors;
                        if (errors && errors.length > 0) {
                            notify.error(this.$t('remove-organization-user-error'));
                        } else {
                            const organizationName = utils.escapeHTML(this.organization.enterpriseName);
                            const userDisplayName = utils.escapeHTML(this.userRemovedDisplayName);
                            notify.success(this.$t('remove-organization-user-success', { organizationName: organizationName, userDisplayName: userDisplayName }));
                        }
                    } catch {
                        notify.error(this.$t('remove-organization-user-error'));
                    }
                } else if (this.userRemoved && this.userRemoved.invitationId) {
                    try {
                        const deleteResult = await this.$apollo.query({
                            query: gql`mutation DeleteUserInvitation($invitationId: String) {
                                deleteUserInvitation(invitationId: $invitationId) {
                                    errors {
                                        code
                                        detail
                                    }
                                }
                            }`,
                            variables: {
                                invitationId: this.userRemoved.invitationId,
                            },
                        });

                        const errors = deleteResult.data.deleteUserInvitation.errors;
                        if (errors && errors.length > 0) {
                            notify.error(this.$t('remove-organization-user-error'));
                        } else {
                            notify.success(this.$t('remove-organization-user-success', { organizationName: this.organization.enterpriseName, userDisplayName: this.userRemovedDisplayName }));
                        }
                    } catch {
                        notify.error(this.$t('remove-organization-user-error'));
                    }
                } else {
                    notify.error(this.$t('err-unknown'));
                    return;
                }

                await this.fetchList();

                this.closeUserRemovalPopup();
            },
            openUserRemovalPopup (user) {
                this.userRemoved = user;
                this.userRemovalPopup = true;
            },
            closeUserRemovalPopup () {
                this.userRemoved = null;
                this.userRemovalPopup = false;
            },
            openUserResendInvitationPopup (user) {
                this.userResend = user;
                this.userResendInvitationPopup = true;
            },
            closeUserResendInvitationPopup () {
                this.userResend = null;
                this.userResendInvitationPopup = false;
            },
            userStatusBackgroundClass (status) {
                switch (status) {
                    case 'active':
                        return 'bg-green-300';
                    case 'invitation-sent':
                        return 'bg-orange-300';
                    case 'invitation-expired':
                        return 'bg-red-300';
                    default:
                        return 'bg-grey-300';
                }
            },
        },
    };
</script>
