import { authFetch as fetch } from './fetch';

export function getUser () {
    return fetch('/bff/graphql', {
        method: 'POST',
        headers: {
            'Content-type': 'application/json',
        },
        body: JSON.stringify({
            query: `{
              userInfo {
                userId
                username
                email
                firstName
                lastName
                organizationIds
                organizations {
                    role
                    organizationId
                }
                environmentIds
                environments {
                    organizationId
                    environmentId
                }
                resellerIds
                resellers {
                    role
                    resellerId
                }
              }
            }`,
        }),
    });
}
