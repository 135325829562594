<script>
export default {
    name: 'dropdown-item',
    props: {
        id: String,
        default: {
            type: Boolean,
            default: false,
        },
    },
    mounted () {
        if (this.default || this.$parent.selected === this.id) {
            this.activate();
        }
    },
    methods: {
        activate (e) {
            this.$parent.$emit('activate', this.id);
            this.$emit('click', this.id);
        },
    },
};
</script>

<template>
    <div @click='activate' class='dropdown-v2-item'>
        <slot></slot>
    </div>
</template>
