<template>
    <li>
        <div class='current-environment'>
            <div class='current-environment__head'>
                {{ $t('nav-current-environment-head') }}
            </div>
            <div class='current-environment__name'>
                {{ name }}
            </div>
        </div>
    </li>
</template>

<script>
export default {
    name: 'sidenav-current-env',
    props: {
        name: String,
    },
};
</script>

<style scoped>
.current-environment {
    @apply w-full;
    box-sizing: border-box;
}

.current-environment__head {
    @apply text-grey-300;
}

.current-environment__name {
    @apply text-xl font-bold break-words;
}
</style>
