var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.close),expression:"close"}],staticClass:"dropdown-v2"},[_c('div',{staticClass:"dropdown-v2-button",class:[{
            "dropdown-v2-button-small": _vm.buttonSmall,
            "semi-transparent": _vm.semiTransparent,
            "transparent": _vm.transparent,
            "whiteName": _vm.whiteName,
            "accordion": _vm.accordion,
            "noMargins": _vm.noMargins,
            menuOpened: _vm.menuOpened
        }],on:{"click":_vm.toggle}},[(_vm.name)?_c('span',[_vm._v(_vm._s(_vm.name))]):_vm._e(),(_vm.selected)?_c('span',{staticClass:"flex items-center"},[(_vm.name && !_vm.noSelected)?_c('span',{staticClass:"separator mr-2",class:{ "semi-transparent": _vm.semiTransparent }},[_vm._v("|")]):_vm._e(),(!_vm.noSelected)?_c('div',{class:[_vm.buttonExtraClasses],domProps:{"innerHTML":_vm._s(_vm.selectedHtml)}}):_vm._e(),(_vm.nullable)?_c('div',{staticClass:"ml-2 text-grey-300 cursor-pointer hover:text-blue-300",on:{"click":_vm.reset}},[_c('i',{staticClass:"fa fa-times"})]):_vm._e()]):_vm._e(),(_vm.icon)?_c('span',{staticClass:"dropdown-v2-icon",class:[{"dropdown-v2-icon__active": _vm.menuOpened, "dropdown-v2-icon--upper": _vm.icon === "chevron-down"}]},[_c('i',{staticClass:"fa",class:("fa-" + _vm.icon)})]):_vm._e()]),(!_vm.accordion && _vm.transparent)?_c('svg',{staticClass:"dropdown-v2__triangle",class:[{"dropdown-v2__triangle--active": _vm.menuOpened}, _vm.direction],attrs:{"width":"16","height":"6","viewBox":"0 0 16 6","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M8 0.5C6 0.5 0.5 6 0.5 6H15.5C15.5 6 10 0.5 8 0.5Z","fill":"white"}})]):_vm._e(),_c('div',{staticClass:"dropdown-v2-menu",class:[
            {
                "dropdown-v2-menu__active": _vm.menuOpened,
                "accordion": _vm.accordion,
                "transparent": _vm.transparent,
            },
            _vm.direction,
            _vm.menuExtraClasses
        ]},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }