<script>
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import {Auth} from '@aws-amplify/auth';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import FriendlyInput from '@/clientcomponents/FriendlyInput.vue';
import CustomTitle from '@/components/Title';
import notify from '@/notify.js';
import validate from '@/validate.js';
import {PASSWORD_RULES} from '@/constants/common';

export default {
    name: 'reset-password',
    components: {
        NavBar,
        FriendlyButton,
        FriendlyInput,
        CustomTitle,
    },
    data () {
        return {
            cognitoError: '',
            newPassword: '',
            passwordRules: PASSWORD_RULES,
        };
    },
    computed: {
        isNewPasswordStrong () {
            return validate.isPasswordStrong(this.newPassword);
        },
        isPasswordResetSubmitDisabled () {
            return (!this.newPassword || !this.isNewPasswordStrong);
        },
    },
    async beforeRouteEnter (to, from, next) {
        try {
            let currentSession = await store.dispatch('checkSession');
            if (currentSession) {
                next('/');
            }
        } catch (e) {
        }
        next();
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    methods: {
        checkPasswordRule (rgx) {
            return validate.checkPasswordRule(rgx, this.newPassword);
        },
        async resetPassword () {
            const valid = await this.$refs.resetPasswordForm.validate();

            if (valid && !this.isNewPasswordStrong) {
                this.$refs.resetPasswordForm.setErrors({password: this.$t('val-password-not-strong')});
                return;
            }

            try {
                await Auth.forgotPasswordSubmit(this.$route.query.email, this.$route.query.code, this.newPassword);
                notify.success(this.$t('suc-reset-password'));
                this.$router.push({ name: 'login', query: this.email ? { email: this.email } : {} });
            } catch (e) {
                this.cognitoError = this.$t({
                    'Invalid verification code provided, please try again.': 'err-password-reset-code-invalid-expired',
                    'Invalid code provided, please request a code again.': 'err-password-reset-code-invalid-expired',
                }[e.message] || 'err-unknown-retry-later');
            }
        },
    },
};

</script>

<template>
    <div class='reset-password'>
        <NavBar no-nav transparent />
        <div id='reset-password-form' class='reset-password__panel'>
            <div class='w-full mb-12 flex justify-center'>
                <img class='w-56' src='@/assets/mycodabox-logo.svg' alt='Mycodabox&apos;s logo'>
            </div>
            <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                <div v-if='cognitoError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                    {{ cognitoError }}
                </div>

                <ValidationObserver ref='resetPasswordForm' tag='div' @keyup.enter='resetPassword'>
                    <CustomTitle class='m-0' :style-of='3'>
                        {{ $t('h-reset-password') }}
                    </CustomTitle>
                    <FriendlyInput
                        autocomplete='new-password'
                        label='lbl-new-password'
                        type='password'
                        v-model='newPassword'
                        field='newPassword'
                        required
                        fullwidth
                        class='mt-5'
                        text-size='xl'
                    />
                    <div class='mt-6'>
                        <ul class='list-none p-0 text-blue-400'>
                            <li class='list-none' v-for='rule in passwordRules' :key='rule.label'>
                                <span
                                    v-if='newPassword.length > 0 && checkPasswordRule(rule.regex)'
                                    class='text-green-300'
                                >
                                    <i class='fa fa-check mr-2'></i>{{ $t(rule.label) }}
                                </span>
                                <span v-else class='text-red-300'>
                                    <i class='fa fa-times mr-2'></i>{{ $t(rule.label) }}
                                </span>
                            </li>
                        </ul>
                    </div>
                    <div class='mt-6 mb-3'>
                        <FriendlyButton label='btn-reset-password'
                                        no-margin
                                        fullwidth
                                        square
                                        small
                                        :action='resetPassword'
                                        :disabled='isPasswordResetSubmitDisabled'
                        />
                    </div>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<style scoped>
.reset-password {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.reset-password__panel {
    @apply pb-24 my-auto;
    max-width: 430px;
    width: 90%;
}
</style>
