<script>
    import i18n from '../i18n.js';
    import flagEn from '../assets/flag-en.svg';
    import flagBe from '../assets/flag-be.svg';
    import DropdownV2 from '@/components/DropdownV2.vue';
    import DropdownItem from '@/components/DropdownItem';

    export default {
        name: 'language-switcher',
        components: {
            DropdownItem,
            DropdownV2,
        },
        data () {
            return {
                flagEn: flagEn,
                flagBe: flagBe,
                flagFr: flagBe,
            };
        },
        computed: {
            lang () {
                return this.$i18n.locale();
            },
            lng () {
                return this.$i18n.locale().split('_')[0];
            },
            flagSrc () {
                return {
                    'en_US': flagEn,
                    'fr_FR': flagBe,
                    'nl_BE': flagBe,
                }[this.$i18n.locale()];
            },
        },
        methods: {
            setLang (lang) {
                i18n.setLocale(lang);
            },
        },
    };
</script>

<template>
    <DropdownV2 class='z-50 py-3 sm:py-0 mb-6 sm:mb-0 font-bold'
                transparent
                button-small
                white-name
                :value='lang'
                :direction='$store.state.gui === "mobile" ? "topcenter" : "right"'
                button-extra-classes='hide-lang-text'
                icon=''
                @changed='setLang'
    >
        <DropdownItem
            id='en_US'
            class='whitespace-nowrap'
        >
            <img class='flag' :src='flagEn' alt='English'>
            <span class='lang-text'>
                English
            </span>
        </DropdownItem>
        <DropdownItem
            id='nl_BE'
            class='whitespace-nowrap'
        >
            <img class='flag' :src='flagBe' alt='Nederlands'>
            <span class='lang-text'>
                Nederlands
            </span>
        </DropdownItem>
        <DropdownItem
            id='fr_FR'
            class='whitespace-nowrap'
        >
            <img class='flag' :src='flagFr' alt='Français'>
            <span class='lang-text'>
                Français
            </span>
        </DropdownItem>
    </DropdownV2>
</template>

<style>
    .cb-lang {
        margin-right: 10px;
    }

    .lang-text {
        @apply ml-1;
    }

    .hide-lang-text .lang-text {
        display: none;
    }
</style>
