
<script>
    import notify from '@/notify.js';
    import router from '@/router.js';
    import NavBar from '@/components/NavBar.vue';
    import { Auth } from '@aws-amplify/auth';
    import FriendlyInput from '@/clientcomponents/FriendlyInput.vue';
    import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
    import CustomTitle from '@/components/Title.vue';
    import validate from '@/validate.js';
    import {PASSWORD_RULES} from '@/constants/common';

    export default {
        name: 'change-password',
        components: {
            CustomTitle,
            FriendlyButton,
            FriendlyInput,
            NavBar,
        },
        data () {
            return {
                currentPassword: '',
                newPassword: '',
                cognitoError: '',
                isChangePasswordOngoing: false,
                passwordRules: PASSWORD_RULES,
            };
        },
        computed: {
            isNewPasswordStrong () {
                return validate.isPasswordStrong(this.newPassword);
            },
            isChangePasswordSubmitDisabled () {
                return (!this.currentPassword || !this.newPassword || !this.isNewPasswordStrong);
            },
        },
        methods: {
            checkPasswordRule (rgx) {
                return validate.checkPasswordRule(rgx, this.newPassword);
            },
            async changePassword () {
                if (this.isChangePasswordOngoing) return;

                const valid = await this.$refs.changePasswordForm.validate();

                if (valid && !this.isNewPasswordStrong) {
                    this.$refs.newPasswordInput.$refs.validation.setErrors([this.$t('val-password-not-strong')]);
                    return;
                }

                this.isChangePasswordOngoing = true;

                // Change password in Cognito
                try {
                    const currentAuthenticatedUser = await Auth.currentAuthenticatedUser();

                    await Auth.changePassword(currentAuthenticatedUser, this.currentPassword, this.newPassword);

                    notify.success(this.$t('suc-change-password'));
                    router.push('/');
                } catch (err) {
                    if (err) {
                        if (err.name === 'NotAuthorizedException') {
                            this.$refs.currentPasswordInput.$refs.validation.setErrors([this.$t('err-invalid-password')]);
                        } else if (err.name === 'LimitExceededException') {
                            this.cognitoError = this.$t('err-change-password-limit-exceeded');
                        } else {
                            this.cognitoError = this.$t('err-unknown-retry-later');
                        }
                    } else {
                        this.cognitoError = this.$t('err-unknown-retry-later');
                    }
                }

                this.isChangePasswordOngoing = false;
            },
        },
    };
</script>

<template>
    <div class='change-password'>
        <NavBar :user='$store.state.user' />

        <div id='change-password-form' class='change-password__panel'>
            <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                <div v-if='cognitoError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                    {{ cognitoError }}
                </div>

                <ValidationObserver ref='changePasswordForm' tag='div' @keyup.enter='changePassword'>
                    <CustomTitle class='m-0' :style-of='3'>
                        {{ $t('h-change-password') }}
                    </CustomTitle>

                    <FriendlyInput
                        autocomplete='current-password'
                        label='lbl-current-password'
                        type='password'
                        v-model='currentPassword'
                        field='currentPassword'
                        required
                        fullwidth
                        text-size='xl'
                        class='mt-3'
                        ref='currentPasswordInput'
                    />

                    <FriendlyInput
                        autocomplete='new-password'
                        label='lbl-new-password'
                        type='password'
                        v-model='newPassword'
                        field='newPassword'
                        required
                        fullwidth
                        class='mt-3'
                        text-size='xl'
                        ref='newPasswordInput'
                    />
                    <div class='mt-6'>
                        <ul class='list-none p-0 text-blue-400'>
                            <li class='list-none' v-for='rule in passwordRules' :key='rule.label'>
                                <span
                                    v-if='newPassword.length > 0 && checkPasswordRule(rule.regex)'
                                    class='text-green-300'
                                >
                                    <i class='fa fa-check mr-2'></i>{{ $t(rule.label) }}
                                </span>
                                <span v-else class='text-red-300'>
                                    <i class='fa fa-times mr-2'></i>{{ $t(rule.label) }}
                                </span>
                            </li>
                        </ul>
                    </div>

                    <div class='mt-6 mb-3'>
                        <FriendlyButton label='btn-change-password'
                                        no-margin
                                        fullwidth
                                        square
                                        small
                                        :action='changePassword'
                                        :disabled='isChangePasswordSubmitDisabled'
                        />
                    </div>

                    <router-link to='/'>
                        {{ $t('a-back-to-mycodabox') }}
                    </router-link>
                </ValidationObserver>
            </div>
        </div>
    </div>
</template>

<style scoped>
.change-password {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.change-password__panel {
    @apply pb-24 my-auto;
    max-width: 430px;
    width: 90%;
}
</style>
