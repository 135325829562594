<template>
    <div class='h-full'>
        <NavBar :user='$store.state.user' :help-url='$t("help-url")' />
        <div v-if='validSession && noOrganizationAccess && noResellerAccess'
             class='container flex flex-col items-center justify-center h-full pb-12'
        >
            <CustomTitle class='mb-6 font-black text-center'>
                {{ $t('ttl-not-linked-to-organization') }}
            </CustomTitle>
            <p class='text-center text-xl max-w-4xl mx-auto'>
                {{ $t('p-not-linked-to-organization') }} <a href='mailto:helpdesk@codabox.com' class='text-blue-400'>helpdesk@codabox.com</a>
            </p>
        </div>
    </div>
</template>
<script>
import CustomTitle from '@/components/Title';
import NavBar from '../components/NavBar.vue';

export default {
    name: 'home',
    components: {
        CustomTitle,
        NavBar,
    },
    data: function () {
        return {
            validSession: false,
        };
    },
    computed: {
        noOrganizationAccess () {
            return this.$store.state.user.organizationIds.length === 0;
        },
        noResellerAccess () {
            return this.$store.state.user.resellerIds.length === 0;
        },
    },
    async mounted () {
        try {
            await this.$store.dispatch('checkSession');
            this.validSession = true;
        } catch (e) {
            this.validSession = false;
        }
    },
};
</script>
