<template>
    <div class='h-full'>
        <NavBar no-nav transparent />

        <div class='flex flex-col items-center justify-center h-full'>
            <svg xmlns='http://www.w3.org/2000/svg' class='h-24 w-24 mb-6 text-red-300' viewBox='0 0 20 20' fill='currentColor'>
                <path fill-rule='evenodd' d='M10 0a10 10 0 100 20 10 10 0 000-20zm0 18.75a8.75 8.75 0 110-17.5 8.75 8.75 0 010 17.5zm-.625-5.625h1.25v-5h-1.25v5zm0 3.125h1.25v-1.25h-1.25v1.25z' clip-rule='evenodd' />
            </svg>
            <CustomTitle class='mb-6 font-black'>
                {{ $t('ttl-page-not-found') }}
            </CustomTitle>
            <p class='text-center text-xl max-w-4xl mx-auto'>
                {{ $t('p-page-not-found') }}
            </p>
            <p class='text-center text-xl max-w-4xl mx-auto'>
                <router-link to='/'>
                    {{ $t('a-back-to-mycodabox') }}
                </router-link>
            </p>
        </div>
    </div>
</template>

<script>
import CustomTitle from '@/components/Title';
import NavBar from '@/components/NavBar.vue';

export default {
    name: 'home',
    components: {
        CustomTitle,
        NavBar,
    },
};
</script>
