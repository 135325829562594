<script>
    import { gql } from '@apollo/client/core';
    import IBAN from 'iban';
    import bus from '../bus.js';
    import { mapState } from 'vuex';

    export default {
        name: 'iban-input',
        props: {
            fid: {type: String, default: null},
            field: {type: String, default: ''},
            value: {type: String, default: ''},
            checkBank: {type: Boolean, default: true},
            label: {type: String, default: ''},
            noLabel: {type: Boolean, default: false},
            formField: {type: Boolean, default: false},
        },
        data () {
            return {
                PLACEHOLDER: 'BE__ ____ ____ ____',
                FORMAT:      'AADD DDDD DDDD DDDD',
                detectedBank: '',
                content: this.value,
                loading: false,
            };
        },
        computed: {
            displayLabel () { return this.label !== null ? this.label : this.$t('lbl-iban'); },
            placeholder () { return this.getPlaceholder(this.value); },
            ...mapState(['allBanks']),
        },
        mounted () {
            bus.$on('validate', this.validate);
            this.content = this.formatIban(this.value || '');
        },
        beforeDestroy () {
            bus.$off('validate', this.validate);
        },
        methods: {
            sanitizeIBAN (iban) {
                if (iban) {
                    return iban.replace(/\s+/g, '');
                } else {
                    return iban;
                }
            },
            formatIban (input) {
                input = input.replace(/\s+/g, '').split('');
                var res = '';
                for (var i = 0; i < this.FORMAT.length && input.length; i++) {
                    var F = this.FORMAT[i];
                    if (F === ' ') {
                        res += ' ';
                    } else if (F === 'D') {
                        if (input[0].match(/\d/)) {
                            res += input.shift();
                        } else {
                            return res;
                        }
                    } else {
                        res += input.shift();
                    }
                }
                return res.toUpperCase();
            },
            getPlaceholder (input) {
                var res = '';
                for (var i = 0; i < this.PLACEHOLDER.length; i++) {
                    if (i < input.length) {
                        res += ' ';
                    } else {
                        res += this.PLACEHOLDER[i];
                    }
                }
                return res;
            },
            validate () {
                return this.validateIban(this.value, 'required');
            },
            async validateIbanQuery (iban) {
                const { data } = await this.$apollo.query({
                    query: gql`query bankAccountValidation($iban: String!) {
                        bankAccountValidation(iban:$iban) {
                            formatValid
                            bankSupportsCoda
                            blacklisted
                            bankId
                        }
                    }`,
                    variables: {
                        iban: iban,
                    },
                });
                return data.bankAccountValidation;
            },
            async validateIban (iban) {
                this.loading = true;
                this.detectedBank = '';
                if (IBAN.isValid(iban)) {
                    if (!this.checkBank) {
                        this.loading = false;
                        return true;
                    } else {
                        const BAValidation = await this.validateIbanQuery(iban);
                        this.loading = false;

                        // check if iban is blacklisted
                        if (BAValidation.blacklisted) {
                            this.$refs.input.setErrors([this.$t('err-cannot-order')]);
                            return false;
                        }

                        // check if bank account supports CODA
                        if (!BAValidation.bankSupportsCoda) {
                            this.$refs.input.setErrors([this.$t('lgnd-coda-not-supported')]);
                            return false;
                        }

                        // get the iban's corresponding bank
                        const bank = this.allBanks.find(b => b.id === BAValidation.bankId);

                        // if bank is know in our system, we show it to the user
                        if (bank) {
                            this.detectedBank = bank.name;
                            return true;
                        } else {
                            this.$refs.input.setErrors([this.$t('val-unknown-bank')]);
                            return false;
                        }
                    }
                } else {
                    this.loading = false;
                    return false;
                }
            },
            handleInput (value) {
                var res = this.formatIban(this.content);
                this.content = res;
                this.$emit('input', this.sanitizeIBAN(res));
                this.validateIban(res);
            },
            exitField () {
                var res = this.formatIban(this.value);
                this.content = res;
                this.validateIban(res);
                this.$emit('blur');
            },
            selectAll (event) {
                setTimeout(function () {
                    event.target.select();
                }, 0);
            },
        },
    };
</script>
<template>
    <ValidationProvider
        v-slot='{ errors }'
        rules='required|iban'
        ref='input'
        tag='div'
    >
        <div>
            <label v-if='!noLabel && formField'>
                <span class='truncate'>
                    {{ displayLabel }}<span class='required'>*</span>
                </span>
            </label>
        </div>
        <div class='cb-bai inline-flex items-center' :class='{"has-error": errors[0], "w-full": formField}'>
            <label style='font-weight: 300;' v-if='!noLabel && !formField'>{{ displayLabel }}

            </label>
            <div class='cb-bai-input form-control mr-0'>
                <input class='cb-bai-input-input'
                       :class='{"w-full": formField}'
                       type='text'
                       autocomplete='off'
                       name='iban'
                       ref='input'
                       v-model='content'
                       @input='handleInput'
                       @focus='selectAll'
                       @blur='exitField'
                       :id='fid'
                       :placeholder='placeholder'
                >
            </div>
            <template v-if='errors[0] && !formField'>
                <div class='cb-bai-status'>
                    <span class='cb-bai-status-icon'>
                        <i class='fa fa-times-circle'></i>
                    </span>
                    <span class='cb-bai-status-bank whitespace-nowrap' v-html='errors[0]'></span>
                </div>
            </template>
            <template v-if='!errors[0] && detectedBank'>
                <div class='cb-bai-status'>
                    <span class='cb-bai-status-icon'>
                        <i class='fa fa-check-circle'></i>
                    </span>
                    <span class='cb-bai-status-bank whitespace-nowrap'>
                        {{ detectedBank }}
                    </span>
                </div>
            </template>
            <div v-if='loading'>
                <i class='fa fa-circle-o-notch fa-spin text-grey-300'></i>
            </div>
        </div>
        <div v-if='errors[0] && formField' class='error-message'>
            <span v-html='errors[0]'></span>
        </div>
    </ValidationProvider>
</template>

<style lang='scss' scoped>
/* ----------------- *\
   *    IBAN INPUT     *
  \* ----------------- */

.cb-bai-input {
  min-width: 185px;
  position: relative;
}

.cb-bai-input-input,
.cb-bai-input-format {
  padding: 0;
  margin: 0;
  display: inline-block;
  background: transparent;
  border: none;
  position: absolute;
  white-space: pre;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  z-index: 10;
}
.cb-bai-input-input:focus {
  outline: none;
  border: none;
}
.cb-bai-input-format {
  color: #cbcbcb;
  z-index: 0;
}

.cb-bai-status {
  display: inline-flex;
  margin-left: 10px;
  padding: 7px 13px;
  background: rgba(103, 189, 37, 0.11);
  color: #4e8f1c;
  border-radius: 4px;
  vertical-align: middle;
}
.has-error .cb-bai-status {
  background: rgba(255, 0, 0, 0.1);
  color: #ff0000;
}

.cb-bai-status-icon {
  margin-right: 7px;
}

@media (max-width: 767px) {
  .cb-bai-status {
    display: block;
    margin-top: 10px;
    margin-right: 0px;
  }

  .cb-btn-tr {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 12px;
    padding: 0px 11px;
    border-radius: 5px;
    text-align: center;
  }
}

.required {
    color: $primary-color;
}

</style>
