<script>
import Settings from '@/components/Settings';
import SettingsToggle from '@/components/SettingsToggle';
import CustomTitle from '@/components/Title';
import ContentBox from '@/components/ContentBox';
import { gql } from '@apollo/client/core';
import notify from '@/notify';

export default {
    name: 'fiduciary-settings',
    props: {
        currentFiduciary: {
            type: Object,
        },
    },
    components: {
        'settings': Settings,
        'settings-toggle': SettingsToggle,
        CustomTitle,
        ContentBox,
    },
    data () {
        return {
            currentFiduciaryCopy: {},
        };
    },
    computed: {
        settingsSendStatement: {
            get: function () {
                return this.currentFiduciaryCopy.sendCodaAndSodaByMail;
            },
            set: async function (value) {
                this.currentFiduciaryCopy.sendCodaAndSodaByMail = value;
                try {
                    const { data } = await this.$apollo.mutate({
                        mutation: gql`mutation ($fiduciaryId: String, $input: FiduciaryUpdateInput!) {
                            updateFiduciary(fiduciaryId: $fiduciaryId, input: $input) {
                                data {
                                    sendCodaAndSodaByMail
                                }
                            }
                        }`,
                        variables: {
                            fiduciaryId: this.currentFiduciary.id,
                            input: {
                                sendCodaAndSodaByMail: value,
                            },
                        },
                    });
                    this.$emit('fiduciaryUpdated', data.updateFiduciary.data);
                } catch (e) {
                    notify.error(this.$t('err-unknown'));
                    this.createACurrentFiduciaryCopy();
                }
            },
        },
    },
    watch: {
        currentFiduciary () {
            this.createACurrentFiduciaryCopy();
        },
    },
    created () {
        this.createACurrentFiduciaryCopy();
    },
    methods: {
        createACurrentFiduciaryCopy () {
            this.currentFiduciaryCopy = Object.assign({}, this.currentFiduciary);
        },
    },
};
</script>

<template>
    <div class='fiduciary-settings'>
        <CustomTitle class='mt-6 mb-6'>
            {{ $t('ttl-settings') }}
        </CustomTitle>
        <content-box>
            <settings tag='ul'>
                <settings-toggle
                    tag='li'
                    :info='$t("stngs-send-statement-info")'
                    v-model='settingsSendStatement'
                >
                    {{ $t('stngs-send-statement') }}
                </settings-toggle>
            </settings>
        </content-box>
    </div>
</template>

<style lang="scss" scoped>
    .fiduciary-settings {
        margin-bottom: 100px;
    }
</style>
