<script>
import FormField from '@/components/FormField';
import { gql } from '@apollo/client/core';
import notify from '@/notify';
import Popup from '@/clientcomponents/Popup';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import { datadogRum } from '@datadog/browser-rum';

export default {
    name: 'archive-client-popup',
    props: {
        clientId: String,
        show: Boolean,
    },
    components: {
        FormField,
        Popup,
        FriendlyButton,
    },
    data () {
        return {
            archiveClientPopup: false,
            archiveClientPopupWhy: '',
            archiveClientPopupWhyFreeNote: '',
            confirmClientArchival: false,
        };
    },
    computed: {
        canArchiveClient () {
            return (
                this.archiveClientPopupWhy &&
                (
                    this.archiveClientPopupWhy !== 'archiveReasonOther' ||
                    this.archiveClientPopupWhyFreeNote
                ) &&
                this.confirmClientArchival
            );
        },
    },
    methods: {
        closeArchiveClientPopup () {
            // log the closing of the popup
            datadogRum.addAction('clientArchivePopupClose');
            this.$emit('close');
        },
        async archiveClient () {
            try {
                await this.$apollo.mutate({
                    mutation: gql`mutation ArchiveClient ($clientId: String!, $reason: ClientArchiveReason!, $reasonText: String) {
                        archiveClient(clientId: $clientId, reason: $reason, reasonText: $reasonText) {
                            errors {
                                code,
                                detail,
                                source {
                                    pointer
                                }
                            }
                        }
                    }`,
                    variables: {
                        clientId: this.clientId,
                        reason: this.archiveClientPopupWhy,
                        reasonText: this.archiveClientPopupWhyFreeNote,
                    },
                });
            } catch {
                notify.error(this.$t('err-server-fail'));
            }

            notify.success(this.$t('lbl-archive-client-success'));
            this.$router.push({ name: 'fidu-client-search' });
        },
    },
};
</script>

<template>
    <Popup
        :show='show'
        :close='closeArchiveClientPopup'
        small
        vid='client-archive-popup'
    >
        <template slot='title'>
            {{ $t('ttl-client-archive') }}
        </template>
        <p>
            {{ $t('p-client-archive-1') }}
        </p>

        <p>
            <strong>
                {{ $t('p-client-archive-2') }}
            </strong>
        </p>

        <div class='mt-6' id='client-archive-popup-reasons'>
            <span class='block mb-2'>
                <p-radio
                    name='reason'
                    v-model='archiveClientPopupWhy'
                    value='archiveReasonClientStopActivity'
                    id='decline'
                >
                    <span class='ml-2'>
                        {{ $t('p-client-archive-reason-1') }}
                    </span>
                </p-radio>
            </span>
            <span class='block mb-2'>
                <p-radio
                    name='reason'
                    v-model='archiveClientPopupWhy'
                    value='archiveReasonSwitchAccountant'
                    id='decline'
                >
                    <span class='ml-2'>
                        {{ $t('p-client-archive-reason-2') }}
                    </span>
                </p-radio>
            </span>
            <span class='block mb-2'>
                <p-radio
                    name='reason'
                    v-model='archiveClientPopupWhy'
                    value='archiveReasonClientStopCodabox'
                    id='decline'
                >
                    <span class='ml-2'>
                        {{ $t('p-client-archive-reason-3') }}
                    </span>
                </p-radio>
            </span>
            <span class='block mb-2'>
                <p-radio
                    name='reason'
                    v-model='archiveClientPopupWhy'
                    value='archiveReasonCost'
                    id='decline'
                >
                    <span class='ml-2'>
                        {{ $t('p-client-archive-reason-4') }}
                    </span>
                </p-radio>
            </span>
            <span class='block mb-2'>
                <p-radio
                    name='reason'
                    v-model='archiveClientPopupWhy'
                    value='archiveReasonOther'
                    id='decline'
                >
                    <span class='ml-2'>
                        {{ $t('p-client-archive-reason-5') }}
                    </span>
                </p-radio>
            </span>
            <span class='block mt-3'>
                <FormField
                    v-model='archiveClientPopupWhyFreeNote'
                    vid='client-archive-popup-freenote'
                    type='textarea'
                    :placeholder='$t("lbl-archive-client-reason-other")'
                    v-if='archiveClientPopupWhy === "archiveReasonOther"'
                    :max='255'
                    edit
                    required
                />
            </span>
        </div>

        <div class='mt-5'>
            <p-check v-model='confirmClientArchival' id='client-archive-popup-confirmation-check'>
                {{ $t('lbl-confirm-archive') }}
            </p-check>
        </div>

        <template slot='buttons'>
            <div class='flex'>
                <FriendlyButton
                    label='btn-cancel'
                    class='mr-3 ml-auto'
                    :action='closeArchiveClientPopup'
                    symbol='times'
                    small
                    no-margin
                    secondary
                    id='client-archive-popup-cancel'
                    datadog-action='clientArchivePopupCancel'
                />
                <FriendlyButton
                    label='btn-action-archive-client'
                    small
                    no-margin
                    symbol='check'
                    :disabled='!canArchiveClient'
                    :action='archiveClient'
                    id='client-archive-popup-do-archive'
                    datadog-action='clientArchivePopupSubmit'
                />
            </div>
        </template>
    </Popup>
</template>
