<script>
import NavBar from '@/components/NavBar.vue';
import store from '@/store';
import {Auth} from '@aws-amplify/auth';
import authUtils from '@/auth/utils';
import FriendlyButton from '@/clientcomponents/FriendlyButton.vue';
import FriendlyInput from '@/clientcomponents/FriendlyInput.vue';
import MigrationPopup from '@/user/MigrationPopup.vue';
import utils from '@/utils';

export default {
    name: 'login',
    components: {
        MigrationPopup, // FIXME MIGRATION_CLEAN: this component should be removed when all users are migrated
        NavBar,
        FriendlyButton,
        FriendlyInput,
    },
    data () {
        return {
            login: this.$route.query.email && this.validateEmail(this.$route.query.email) ? this.$route.query.email : '', // FIXME MIGRATION_CLEAN: this variable can be renamed to email
            password: '',
            isLoginOngoing: false,
            coreToken: '', // FIXME MIGRATION_CLEAN: should be removed
            coreData: null, // FIXME MIGRATION_CLEAN: should be removed
            loginError: '',
            showLoginError: false, // FIXME MIGRATION_CLEAN: can be removed
            showMigrationPopup: false, // FIXME MIGRATION_CLEAN: can be removed
        };
    },
    async beforeRouteEnter (to, from, next) {
        try {
            let currentSession = await store.dispatch('checkSession');
            if (currentSession) {
                next('/');
            }
        } catch (e) {
        }
        next();
    },
    watch: {
        '$store.state.maintenance': function () {
            store.dispatch('logout');
        },
    },
    methods: {
        async performLogin () {
            if (this.isLoginOngoing) return;
            if (!this.$refs.loginForm || !(await this.$refs.loginForm.validate())) return;
            this.loginError = '';
            this.showLoginError = false;
            this.isLoginOngoing = true;
            // Case e-mail
            if (utils.validateEmail(this.login) && await this.performCognitoLogin()) {
                this.$router.push('/home');
            } else { // FIXME MIGRATION_CLEAN: should be removed
                await this.startMigrationProcedure();
            }
            this.isLoginOngoing = false;
        },
        // FIXME MIGRATION_CLEAN: should be removed
        openMigrationPopup () {
            this.showMigrationPopup = true;
        },
        // FIXME MIGRATION_CLEAN: should be removed
        closeMigrationPopup () {
            this.showMigrationPopup = false;
        },
        async performCognitoLogin () {
            try {
                let cognitoUser = await Auth.signIn(this.login, this.password);
                authUtils.setAuthToken(cognitoUser.signInUserSession.accessToken.jwtToken);
                authUtils.setIdToken(cognitoUser.signInUserSession.idToken.jwtToken);
                await this.$store.dispatch('loadUser');
                return true;
            } catch (e) {
                // FIXME MIGRATION_CLEAN: show all error messages again
                if (e.message === 'Password attempts exceeded') {
                    this.loginError = this.$t('err-login-attempts-exceeded');
                    this.showLoginError = true;
                }
                return false;
            }
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async getTokenFromCore () {
            try {
                const response = await fetch('/bff/api/user-migration/authenticate', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        username: this.login,
                        password: this.password,
                    }),
                });
                if (!response.ok) {
                    return null;
                }
                return (await response.json()).token;
            } catch (e) {
                return null;
            }
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async getDataFromCore () {
            try {
                const infoResponse = await fetch('/bff/api/user-migration/info', {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'CORE-TOKEN': this.coreToken,
                    },
                });
                if (infoResponse.status !== 200) {
                    return null;
                }
                return (await infoResponse.json());
            } catch (e) {
                return null;
            }
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async startMigrationProcedure () {
            if (this.showLoginError) return;
            this.coreToken = await this.getTokenFromCore();
            this.coreData = await this.getDataFromCore();

            if (!this.coreToken) {
                this.loginError = this.$t('err-invalid-migration-credentials');
                this.showLoginError = true;
                return;
            } else if (!this.coreData) {
                this.loginError = this.$t('err-unknown');
                this.showLoginError = true;
                return;
            }

            this.openMigrationPopup();
        },
        // FIXME MIGRATION_CLEAN: should be removed
        async performMigration (email) {
            if (!email || !this.coreToken || !this.coreData) return false;
            try {
                const invitationResponse = await fetch('/bff/api/user-migration/invite', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'CORE-TOKEN': this.coreToken,
                    },
                    body: JSON.stringify({
                        email: email,
                    }),
                });
                return invitationResponse.status === 201;
            } catch (e) {
                return false;
            }
        },
        validateEmail: utils.validateEmail,
    },
};

</script>

<template>
    <div class='login'>
        <NavBar no-nav transparent />
        <div>
            <!-- FIXME MIGRATION_CLEAN: should be removed -->
            <MigrationPopup
                :show='showMigrationPopup'
                :close='closeMigrationPopup'
                :callback='performMigration'
                :core-data='coreData'
            />
            <div class='w-full mb-12 text-center px-6'>
                <div class='w-full mb-12 flex justify-center'>
                    <img class='w-56' src='@/assets/mycodabox-logo.svg' alt='Mycodabox&apos;s logo'>
                </div>
                <h1 class='font-bold'>
                    {{ $t("ttl-migration-message") }}
                </h1>
                <p class='text-xl'>
                    {{ $t("p-migration-message") }}
                </p>
                <span class='font-bold text-xl'>{{ $t("p-more-info") }}</span> <a class='text-xl'
                                                                                  :href='$t("a-href-faq-migration")'
                                                                                  target='_blank'
                >{{ $t("a-faq") }}</a>
            </div>
            <div id='login-form' class='login__panel'>
                <div class='bg-white shadow-xl drop-shadow-xl py-8 px-12 rounded-xl'>
                    <div v-if='showLoginError && loginError' class='rounded-md bg-red-300 text-white mb-4 px-6 py-3'>
                        {{ loginError }}
                    </div>

                    <ValidationObserver ref='loginForm' tag='div' @keyup.enter='performLogin'>
                        <FriendlyInput
                            label='lbl-login'
                            v-model='login'
                            field='login'
                            required
                            fullwidth
                            text-size='xl'
                        />

                        <FriendlyInput
                            autocomplete='off'
                            label='lbl-password'
                            type='password'
                            v-model='password'
                            field='password'
                            required
                            fullwidth
                            class='mt-3'
                            text-size='xl'
                        />

                        <div class='mt-6'>
                            <FriendlyButton label='btn-login'
                                            no-margin
                                            fullwidth
                                            square
                                            small
                                            :action='performLogin'
                                            :disabled='password.length === 0 || login.length === 0'
                            />
                        </div>

                        <router-link :to='{ name: "forgot-password", query: login && validateEmail(login) ? { email: login } : {} }' class='inline-block mt-3'>
                            {{ $t('a-forgot-password') }}
                        </router-link>
                    </ValidationObserver>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.login {
    display: flex;
    background-size: cover;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 4rem);
}

.login__panel {
    @apply pb-64 my-auto mx-auto;
    max-width: 430px;
    width: 90%;
}
</style>
