<template>
    <div class='sidenav' :class='{"sidenav--collapsed": collapsed}'>
        <div class='sidenav__inner'>
            <ul class='sidenav__list'>
                <slot></slot>
            </ul>
            <div class='sidenav__footer'>
                <a href='mailto:helpdesk@codabox.com' class='block mb-2'>
                    <i class='fa fa-envelope mr-3 text-lg'></i>
                    <span class='text-blue-500'>
                        helpdesk@codabox.com
                    </span>
                </a>
                <a href='tel:003228808480' class='block'>
                    <i class='fa fa-phone mr-3 text-lg'></i>
                    <span class='text-blue-500'>
                        +32 2 880 84 80
                    </span>
                </a>
                <div class='mt-3 mr-3'>
                    <a target='_blank' href='https://www.codabox.com'>
                        <img src='@/assets/mycodabox-logo.svg' alt='Codabox logo' class='w-48'>
                    </a>
                </div>
            </div>
        </div>
        <div class='sidenav-border' @click='collapse'>
            <div class='collapse-button'>
                <i :class='`fa ${collapseIcon}`'></i>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'sidenav',
    data () {
        return {
            collapsed: false,
        };
    },
    computed: {
        collapseIcon () {
            return this.collapsed ? 'fa-arrow-right' : 'fa-arrow-left';
        },
    },
    methods: {
        collapse () {
            this.collapsed = !this.collapsed;
        },
    },
};
</script>

<style scoped>
    .sidenav {
        @apply relative transition-all duration-200 ease-in-out lg:h-full mr-3;
        flex-shrink: 0;
        width: 100%;
    }

    @screen lg {
        .sidenav {
            width: 280px;
        }
    }

    .sidenav:hover {
        @apply relative;
        flex-shrink: 0;
    }

    .sidenav__inner {
        @apply flex flex-col p-6 pr-3 transition-all duration-200 ease-in-out h-full overflow-auto;
        pointer-events: auto;
        width: 100%;
    }

    @screen lg {
        .sidenav__inner {
            width: 280px;
        }
    }

    .sidenav__list {
        @apply list-none p-0 mb-auto;
    }

    .sidenav__footer {
        @apply text-sm ml-1 mr-3;
    }

    .sidenav--collapsed {
        width: 60px;
    }

    .sidenav--collapsed .sidenav__inner {
        opacity: 0;
        pointer-events: none;
    }

    .sidenav--collapsed .sidenav-border  {
        right: 18px;
        opacity: 100;
    }

    .sidenav--collapsed .collapse-button {
        transform: translateX(0);
    }

    .sidenav:hover .sidenav-border {
        @apply opacity-100;
        pointer-events: auto;
    }

    .sidenav:hover .collapse-button {
        transform: translateX(0);
    }

    .sidenav-border {
        @apply absolute right-0 top-0 bottom-0 transition duration-200 ease-in-out opacity-0 cursor-pointer w-3 hidden lg:block;
        right: -10px;
        pointer-events: none;
    }

    .sidenav-border:hover:after {
        @apply bg-blue-400;
    }

    .sidenav-border:after {
        @apply absolute top-6 bottom-6 bg-grey-250 rounded transition duration-200 ease-in-out opacity-100 cursor-pointer w-1;
        pointer-events: none;
        right: 9px;
        content: ' ';
    }

    .collapse-button {
        @apply absolute bg-white w-10 h-10 shadow-md rounded-full flex items-center justify-center text-sm z-10 transition duration-200 ease-in-out;
        transform: translateX(6px);
        right: -8px;
        top: 50%;
    }
</style>
