<template>
    <div class='custom-tooltip'>
        <div :class='{faded}'>
            <slot name='trigger'></slot>
        </div>
        <div class='custom-tooltip__content' :class='{"whitespace-nowrap": noWrap, large, left, right}'>
            <slot name='content'></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        noWrap: {
            type: Boolean,
            default: false,
        },
        faded: {
            type: Boolean,
            default: false,
        },
        large: {
            type: Boolean,
            default: false,
        },
        left: {
            type: Boolean,
            default: false,
        },
        right: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style lang="postcss" scoped>
.custom-tooltip {
    @apply relative inline-block cursor-default;
    z-index: 20;
}

.faded {
    @apply opacity-75 transition duration-200 ease-in-out;
}

.custom-tooltip__content {
    @apply shadow-lg bg-white rounded-md p-6 inline-block absolute opacity-0 pointer-events-none transition duration-200 ease-in-out;
    top: 100%;
    min-width: 300px;
    transform: translateX(-50%);
}

.custom-tooltip__content.large {
    min-width: 600px;
}

.custom-tooltip__content.left {
    left: auto;
    right: 0;
    @apply transform translate-x-0;
}

.custom-tooltip__content.right {
    right: auto;
    left: 0;
    @apply transform translate-x-0;
}

.custom-tooltip:hover {
    z-index: 25;
}

.custom-tooltip:hover .custom-tooltip__content {
    @apply opacity-100 pointer-events-auto;
}

.custom-tooltip:hover .faded {
    @apply opacity-100;
}
</style>
