var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inline-block"},[_c('div',{staticClass:"cb-friendly-button",class:{
             secondary:_vm.secondary,
             warning: _vm.warning,
             danger: _vm.danger,
             enabled:!(_vm.disabled||_vm.busy),
             disabled:_vm.disabled,
             busy:_vm.busy,
             small: _vm.small,
             "extra-small": _vm.extraSmall,
             "no-margin": _vm.noMargin,
             square: _vm.square,
             micro: _vm.micro,
             invertedIcon: _vm.invertedIcon,
             noBackground: _vm.noBackground,
             fatIcon: _vm.fatIcon,
             fullwidth: _vm.fullwidth
         },on:{"click":_vm.doClick}},[(!_vm.noText)?_c('span',{class:[_vm.symbol ? _vm.invertedIcon ? "ml-3 mr-0" : "mr-3 ml-0" : ""]},[_vm._v(" "+_vm._s(_vm.$t(_vm.label))+" ")]):_vm._e(),(_vm.symbol)?_c('i',{class:[_vm.symbolClass]}):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }